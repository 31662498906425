import { useQuery } from "react-query";
import axios from "../axios";
import qs from "query-string";

export type RewardResponse = {
  code: number;
  message: string;
  data: {
    content: {
      id: number;
      title: string;
      type?: string;
    }[];
  };
};

export default function useReward() {
  return useQuery(["reward"], async () => {
    const endpointUnlocked = qs.stringifyUrl({
      url: "/api/getreward",
      query: {
        size: 100,
      },
    });
    const { data: unlocked } = await axios.get<RewardResponse>(
      endpointUnlocked
    );

    const endpointLocked = qs.stringifyUrl({
      url: "/api/getlockreward",
      query: {
        size: 100,
      },
    });
    const { data: locked } = await axios.get<RewardResponse>(endpointLocked);

    return [...unlocked.data.content, ...locked.data.content];
  });
}

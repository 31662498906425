import * as React from "react";
import { SVGProps } from "react";
const MiniBadgeMember = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <g filter="url(#a)">
            <path
                fill={props.fill ?? "#628CA6"}
                d="m12 14.475 1.925 1.15a.443.443 0 0 0 .537-.012c.175-.125.237-.296.188-.513l-.5-2.175 1.7-1.475a.476.476 0 0 0 .15-.537c-.067-.208-.217-.32-.45-.338l-2.225-.175-.875-2.075a.45.45 0 0 0-.45-.3.45.45 0 0 0-.45.3l-.875 2.075-2.225.175c-.233.017-.383.13-.45.338a.476.476 0 0 0 .15.537l1.7 1.475-.5 2.175c-.05.217.013.388.188.513s.354.13.537.012L12 14.475ZM8.65 20H6c-.55 0-1.021-.196-1.413-.588A1.922 1.922 0 0 1 4 18v-2.65L2.075 13.4c-.183-.2-.325-.42-.425-.662-.1-.241-.15-.487-.15-.738 0-.25.05-.496.15-.738.1-.242.242-.463.425-.662L4 8.65V6c0-.55.196-1.021.588-1.413A1.922 1.922 0 0 1 6 4h2.65l1.95-1.925c.2-.183.421-.325.663-.425.242-.1.488-.15.737-.15.25 0 .496.05.738.15.242.1.463.242.662.425L15.35 4H18c.55 0 1.021.196 1.413.588.392.392.588.863.587 1.412v2.65l1.925 1.95c.183.2.325.421.425.663.1.242.15.488.15.737 0 .25-.05.496-.15.738-.1.242-.242.463-.425.662L20 15.35V18c0 .55-.196 1.021-.588 1.413A1.922 1.922 0 0 1 18 20h-2.65l-1.95 1.925c-.2.183-.42.325-.662.425-.241.1-.487.15-.738.15-.25 0-.496-.05-.737-.15a2.263 2.263 0 0 1-.663-.425L8.65 20Z"
            />
            <path
                fill="url(#b)"
                fillOpacity={0.08}
                d="m12 14.475 1.925 1.15a.443.443 0 0 0 .537-.012c.175-.125.237-.296.188-.513l-.5-2.175 1.7-1.475a.476.476 0 0 0 .15-.537c-.067-.208-.217-.32-.45-.338l-2.225-.175-.875-2.075a.45.45 0 0 0-.45-.3.45.45 0 0 0-.45.3l-.875 2.075-2.225.175c-.233.017-.383.13-.45.338a.476.476 0 0 0 .15.537l1.7 1.475-.5 2.175c-.05.217.013.388.188.513s.354.13.537.012L12 14.475ZM8.65 20H6c-.55 0-1.021-.196-1.413-.588A1.922 1.922 0 0 1 4 18v-2.65L2.075 13.4c-.183-.2-.325-.42-.425-.662-.1-.241-.15-.487-.15-.738 0-.25.05-.496.15-.738.1-.242.242-.463.425-.662L4 8.65V6c0-.55.196-1.021.588-1.413A1.922 1.922 0 0 1 6 4h2.65l1.95-1.925c.2-.183.421-.325.663-.425.242-.1.488-.15.737-.15.25 0 .496.05.738.15.242.1.463.242.662.425L15.35 4H18c.55 0 1.021.196 1.413.588.392.392.588.863.587 1.412v2.65l1.925 1.95c.183.2.325.421.425.663.1.242.15.488.15.737 0 .25-.05.496-.15.738-.1.242-.242.463-.425.662L20 15.35V18c0 .55-.196 1.021-.588 1.413A1.922 1.922 0 0 1 18 20h-2.65l-1.95 1.925c-.2.183-.42.325-.662.425-.241.1-.487.15-.738.15-.25 0-.496-.05-.737-.15a2.263 2.263 0 0 1-.663-.425L8.65 20Z"
            />
        </g>
        <defs>
            <linearGradient
                id="b"
                x1={2}
                x2={23}
                y1={2}
                y2={23}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#717171" />
                <stop offset={0.1} stopColor="#fff" />
                <stop offset={0.2} stopColor="#717171" />
                <stop offset={0.3} stopColor="#fff" />
                <stop offset={0.4} stopColor="#717171" />
                <stop offset={0.5} stopColor="#fff" />
                <stop offset={0.6} stopColor="#717171" />
                <stop offset={0.7} stopColor="#fff" />
                <stop offset={0.8} stopColor="#717171" />
                <stop offset={0.9} stopColor="#fff" />
                <stop offset={1} stopColor="#717171" />
            </linearGradient>
            <filter
                id="a"
                width={26}
                height={26}
                x={-1}
                y={-1}
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dx={-1} dy={-1} />
                <feGaussianBlur stdDeviation={1} />
                <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                <feBlend in2="shape" result="effect1_innerShadow_4829_6565" />
                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dx={1} dy={1} />
                <feGaussianBlur stdDeviation={1} />
                <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
                <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0" />
                <feBlend
                    in2="effect1_innerShadow_4829_6565"
                    result="effect2_innerShadow_4829_6565"
                />
            </filter>
        </defs>
    </svg>
);
export default MiniBadgeMember;

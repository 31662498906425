import React, { ReactNode } from "react";
import { AlertProvider } from "./alertContext";
import { AuthProvider } from "./authContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface IindexProps {
    children: ReactNode;
}

const AppProvider = ({ children }: IindexProps) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AuthProvider>
                <AlertProvider>{children}</AlertProvider>
            </AuthProvider>
        </LocalizationProvider>
    );
};

export default AppProvider;

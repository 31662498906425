import { DefaultOption, DefaultResponse } from "../../types";
import axios from "../axios";
import { useQuery } from "react-query";

export default function useGetStatusResi() {
  return useQuery(
    "invoice-status",
    () =>
      axios
        .get<DefaultResponse<DefaultOption[]>>("/api/getstatusinvoice")
        .then((res) => res.data.data),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
}

import { useEffect, useState } from "react";
import useGetResi from "../../services/queries/useGetResi";
import { DefaultOption, ResiParams } from "../../types";
import moment from "moment";
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridRowsProp,
} from "@mui/x-data-grid";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import MainContainer from "../../components/MainContainer/MainContainer";
import {
  Stack,
  Button,
  Box,
  Container,
  CircularProgress,
  Input,
  FormHelperText,
  InputLabel,
  FormControl,
  OutlinedInput,
  IconButton,
  Autocomplete,
  TextField,
  Chip,
  Modal,
  Typography,
  Badge,
  Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import DeleteIcon from "@mui/icons-material/Delete";
import PinDropIcon from "@mui/icons-material/PinDrop";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import dayjs, { Dayjs } from "dayjs";
import "./ResiPage.css";
import useGetStatusResi from "../../services/queries/useGetStatusResi";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RemoveIcon from "@mui/icons-material/Remove";
import useGetDetailResi from "../../services/queries/useGetDetailResi";
import { Check, Close } from "@mui/icons-material";
import { TicketPercent } from "mdi-material-ui";
import useVoucher from "../../services/queries/useVoucher";
import VoucherCard from "../../components/VoucherCard/VoucherCard";
import useAssignVoucher, {
  AssignVoucherBody,
} from "../../services/queries/useAssignVoucher";
import LoadingButton from "@mui/lab/LoadingButton";
import useResetVoucher from "../../services/queries/useResetVoucher";
import { apiUrl } from "../../constants/common";

const ResiPage = () => {
  const initialParams: ResiParams = {
    size: 10,
    page: 1,
  };

  const [resiParams, setResiParams] = useState<ResiParams>(initialParams);
  const [searchValue, setSearchValue] = useState("");
  const [status, setStatus] = useState<DefaultOption[]>([]);
  const [date, setDate] = useState<Dayjs | null>(null);
  const [dateAkhir, setDateAkhir] = useState<Dayjs | null>(null);
  const [selectedResi, setSelectedResi] = useState<string | undefined>(
    undefined
  );
  const [selectedResiEks, setSelectedResiEks] = useState<string | undefined>(
    undefined
  );
  const [assignVoucher, setAssignVoucher] = useState<{
    no_resi: string;
    voucherId: number | null;
  } | null>(null);

  const resiData = useGetResi(resiParams);
  const resi = resiData.data?.content.map((li, idx) => ({
    nomor: idx + 1,
    no_resi_link: li.no_resi,
    ...li,
  }));

  const rowCount = resiData.data?.total_elements ?? 0;

  const resiStatusData = useGetStatusResi();
  const resiStatus = resiStatusData.data;

  const detailResiData = useGetDetailResi(selectedResi);
  const detailResi = detailResiData.data?.data;

  const detailResiDataEks = useGetDetailResi(selectedResiEks);
  const detailResiEks = detailResiDataEks.data?.data.nama_ekspedisi_resi;

  const handleSearch = () => {
    setResiParams((prev) => ({
      ...prev,
      page: 1,
      no_resi: searchValue,
    }));
  };

  const handleClearFilter = () => {
    setResiParams(initialParams);
    setDate(null);
    setDateAkhir(null);
    setSearchValue("");
    setStatus([]);
  };

  useEffect(() => {
    if (status.length === 0) {
      setResiParams((prev) => ({
        ...prev,
        page: 1,
        status: undefined,
      }));
      return;
    }

    setResiParams((prev) => ({
      ...prev,
      page: 1,
      status: status.map((li) => li.id).join(","),
    }));
  }, [status]);

  useEffect(() => {
    if (!date || !dateAkhir) return;

    setResiParams((prev) => ({
      ...prev,
      page: 1,
      tanggal_awal: date.format("YYYY-MM-DD"),
      tanggal_akhir: dateAkhir.format("YYYY-MM-DD"),
    }));
  }, [date, dateAkhir]);

  const rows: GridRowsProp = resi ?? [];
  const columns: GridColDef[] = [
    {
      field: "nomor",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      maxWidth: 100,
      headerClassName: "header-table",
    },
    {
      field: "no_resi",
      headerName: "No Resi",
      minWidth: 150,
      headerClassName: "header-table",
      renderCell(params) {
        return (
          <Stack spacing={1}>
            <div>{params.value}</div>
            {params.row.voucher && (
              <Chip
                sx={{
                  maxWidth: "120px",
                  "& .MuiChip-label": {
                    fontSize: "10px",
                  },
                }}
                size="small"
                label={params.row.voucher.judul}
                color="success"
                variant="outlined"
              />
            )}
          </Stack>
        );
      },
    },
    {
      field: "carton",
      headerName: "Total Karton",
      headerAlign: "center",
      align: "center",
      minWidth: 140,
      headerClassName: "header-table",
    },
    {
      field: "tanggal_resi",
      headerName: "Tanggal",
      headerAlign: "center",
      align: "center",
      minWidth: 200,
      flex: 1,
      headerClassName: "header-table",
      renderCell(params) {
        return params.value ? params.value.split(",")[0] : params.value;
      },
    },
    {
      field: "status",
      headerName: "Status Resi",
      headerAlign: "center",
      align: "center",
      minWidth: 200,
      headerClassName: "header-table",
      renderCell(params) {
        return (
          <Chip label={params?.value} color="primary" variant="outlined" />
        );
      },
    },
    {
      field: "shipping_instruction",
      headerName: "Shipping",
      headerAlign: "center",
      align: "center",
      minWidth: 200,
      headerClassName: "header-table",
    },
    {
      field: "no_resi_link",
      headerName: "Aksi",
      headerAlign: "center",
      align: "center",
      headerClassName: "header-table",
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              size="small"
              color="error"
              disableElevation
              sx={{
                minWidth: "unset",
                padding: "8px",
                width: "32px",
                height: "32px",
              }}
              onClick={() => {
                setAssignVoucher({
                  no_resi: params.value,
                  voucherId: params.row?.voucher?.id ?? null,
                });
                setSelectedResiEks(params.value);
              }}
              disabled={
                params.row.status === "Kontainer China"
                  ? false
                  : params.row.status === "Warehouse China"
                    ? false
                    : params.row.status === "Pengiriman ke Indonesia"
                      ? false
                      : params.row.status === "Deliveries by Coload"
                        ? false
                        : true
              }
            >
              <Badge badgeContent={params.row.voucher ? 1 : 0} color="primary">
                <TicketPercent sx={{ width: "16px", height: "16px" }} />
              </Badge>
            </Button>
            <a
              href={`https://antaralogistic.com/tracking-resi-antara-logistic/?r=${params.value}`}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                variant="contained"
                size="small"
                color="buttonblue"
                disableElevation
                sx={{
                  minWidth: "unset",
                  padding: "8px",
                  width: "32px",
                  height: "32px",
                }}
              >
                <PinDropIcon sx={{ width: "16px", height: "16px" }} />
              </Button>
            </a>
            <Button
              variant="contained"
              size="small"
              color="buttongreen"
              disableElevation
              sx={{
                minWidth: "unset",
                padding: "8px",
                width: "32px",
                height: "32px",
              }}
              onClick={() => setSelectedResi(params.value)}
            >
              <VisibilityIcon sx={{ width: "16px", height: "16px" }} />
            </Button>
          </Stack>
        );
      },
    },
  ];

  function truncate(str: string, n: number) {
    return str.length > n ? str.slice(0, n - 1) + `...` : str;
  }

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    maxHeight: 700,
    overflow: "auto",
  };

  // if (resiData.isLoading) {
  //     return (
  //         <Stack height="80vh" alignItems="center" justifyContent="center">
  //             <CircularProgress />
  //         </Stack>
  //     );
  // }

  return (
    <MainContainer title="Resi Saya" subtitle="Daftar semua pengiriman resi">
      <Stack paddingY={3} spacing={3}>
        <Stack direction="row" gap={3}>
          <OutlinedInput
            name="search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                handleSearch();
              }
            }}
            sx={{ width: "80%" }}
            placeholder="Cari Berdasarkan Nomor Resi"
            endAdornment={
              <IconButton onClick={handleSearch}>
                <SearchIcon color="secondary" />
              </IconButton>
            }
          />
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClearFilter}
          >
            Reset Filter
          </Button>
        </Stack>
        <Stack direction="row" gap={3}>
          <Autocomplete
            disablePortal
            multiple
            options={resiStatus ?? []}
            value={status}
            onChange={(_, newValue) => {
              setStatus(newValue);
            }}
            getOptionLabel={(option: DefaultOption) => option.nama}
            sx={{ width: "45%" }}
            renderInput={(params) => (
              <TextField {...params} label="Pilih Status Resi" />
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={truncate(option.nama, 10)}
                  {...getTagProps({ index })}
                />
              ))
            }
            limitTags={3}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={3}
            sx={{ width: "50%" }}
          >
            <DatePicker
              sx={{ width: "48%" }}
              label="Pilih Tanggal Awal"
              value={date}
              onChange={(value) => setDate(value)}
            />
            <RemoveIcon />
            <DatePicker
              sx={{ width: "48%" }}
              label="Pilih Tanggal Akhir"
              value={dateAkhir}
              onChange={(value) => setDateAkhir(value)}
            />
          </Stack>
        </Stack>
      </Stack>
      <Box>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                page: initialParams.page,
                pageSize: initialParams.size,
              },
            },
          }}
          checkboxSelection={false}
          getRowId={(row) => row.nomor}
          disableColumnMenu
          disableRowSelectionOnClick
          paginationMode="server"
          rowCount={rowCount}
          loading={resiData.isLoading}
          pageSizeOptions={[10]}
          paginationModel={{
            pageSize: resiParams.size,
            page: resiParams.page - 1,
          }}
          onPaginationModelChange={({ page, pageSize }) => {
            setResiParams((prev) => ({
              ...prev,
              page: page + 1,
              size: pageSize,
            }));
          }}
          density="comfortable"
        />
      </Box>
      <Modal open={!!selectedResi} onClose={() => setSelectedResi(undefined)}>
        <Box sx={style}>
          {detailResiData.isLoading ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              minHeight={200}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack>
                  <Typography variant="h5" fontWeight="bold">
                    {selectedResi}
                  </Typography>
                  <Typography variant="body2">
                    {detailResi?.last_update}
                  </Typography>
                </Stack>
                <IconButton onClick={() => setSelectedResi(undefined)}>
                  <Close />
                </IconButton>
              </Stack>
              {detailResi?.status && (
                <Box my={2}>
                  <Chip
                    label={detailResi?.status}
                    color="primary"
                    variant="outlined"
                  />
                </Box>
              )}
              <Stack spacing={1} mt={4}>
                {detailResi?.eta &&
                  <InfoDescription
                    title="ETA"
                    subtitle={String(moment(detailResi?.eta).format("DD MMM YYYY"))}
                  />}
                <InfoDescription
                  title="Destinasi Awal"
                  subtitle={detailResi?.destinasi_awal ?? "-"}
                />
                <InfoDescription
                  title="Destinasi Akhir"
                  subtitle={detailResi?.destinasi_akhir ?? "-"}
                />
                <InfoDescription
                  title="Local Express"
                  subtitle={detailResi?.no_china_local ?? "-"}
                />
                <InfoDescription
                  title="Ekspedisi"
                  subtitle={detailResi?.nama_ekspedisi_resi ?? "-"}
                />
                <InfoDescription
                  title="Shipping Mark"
                  subtitle={detailResi?.shipping_mark ?? "-"}
                />
                <InfoDescription
                  title="Shipping Instruction"
                  subtitle={detailResi?.shipping_instruction ?? "-"}
                />
                <InfoDescription
                  title="Voucher"
                  subtitle={
                    detailResi?.voucher
                      ? detailResi?.voucher.judul
                      : "Tanpa voucher"
                  }
                />
              </Stack>
              {detailResi?.gambar_carton &&
                detailResi?.gambar_carton.length > 0 && (
                  <Stack spacing={1} mt={4}>
                    <Grid container spacing={1}>
                      {detailResi?.gambar_carton.map((li) => (
                        <Grid item xs={12} md={4} key={li.gambar}>
                          <a
                            href={`${apiUrl}/${li.gambar}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={`${apiUrl}/${li.gambar}`}
                              alt={li.no_carton}
                              style={{ width: 150 }}
                            />
                          </a>
                        </Grid>
                      ))}
                    </Grid>
                  </Stack>
                )}
            </>
          )}
        </Box>
      </Modal>
      <Modal
        open={!!assignVoucher}
        onClose={() => {
          setAssignVoucher(null);
        }}
      >
        {assignVoucher ? (
          <AssignVoucherModal
            no_resi={assignVoucher.no_resi}
            ekspedisi={detailResiEks ?? null}
            selectedVoucherId={assignVoucher.voucherId}
            onClose={() => {
              setAssignVoucher(null);
            }}
          />
        ) : (
          <span></span>
        )}
      </Modal>
    </MainContainer>
  );
};

type InfoDescriptionProps = {
  title: string;
  subtitle: string;
};

const InfoDescription = ({ title, subtitle }: InfoDescriptionProps) => {
  return (
    <Stack>
      <Typography variant="body2">{title}</Typography>
      <Typography variant="h6" fontWeight="bold">
        {subtitle}
      </Typography>
    </Stack>
  );
};

const AssignVoucherModal = ({
  no_resi,
  ekspedisi,
  selectedVoucherId,
  onClose,
}: {
  no_resi: string;
  ekspedisi: string | null;
  selectedVoucherId: number | null;
  onClose: () => void;
}) => {
  const modalStyles = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
  };
  const [selectedVoucher, setSelectedVoucher] = useState<number | null>(null);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const nama_ekspedisi = ekspedisi;
  const voucherQuery = useVoucher({ nama_ekspedisi });
  const voucher = voucherQuery.data;

  const assignVoucher = useAssignVoucher();
  const resetVoucher = useResetVoucher();

  const onSubmitVoucher = () => {
    if (!selectedId) return;

    assignVoucher.mutate(
      {
        no_resi: no_resi,
        voucher_id: selectedId,
      },
      {
        onSuccess() {
          onClose();
        },
      }
    );
  };

  const onResetVoucher = () => {
    resetVoucher.mutate(
      {
        no_resi: no_resi,
      },
      {
        onSuccess() {
          onClose();
        },
      }
    );
  };

  useEffect(() => {
    if (selectedVoucherId) {
      setSelectedVoucher(selectedVoucherId);
      setSelectedId(selectedVoucherId);
    }
  }, [selectedVoucherId]);

  return (
    <Box sx={modalStyles}>
      {voucherQuery.isLoading ? (
        <Box
          p={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight={200}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stack
            p={4}
            style={{
              maxHeight: 580,
              overflow: "auto",
            }}
          >
            <Stack spacing={4}>
              {voucher?.content.map((li) => (
                <VoucherCard
                  key={li.id.toString()}
                  {...li}
                  onClick={() => {
                    if (selectedVoucherId) {
                      if (li.status_voucher && selectedVoucherId !== li.id)
                        return;
                      setSelectedVoucher(li.id);
                      setSelectedId(li.id);
                    } else {
                      if (!li.status_voucher) {
                        setSelectedId(li.id);
                        setSelectedVoucher(li.id);
                      }
                    }
                  }}
                  selected={selectedVoucher === li.id}
                  viewOnly={li.status_voucher && selectedVoucherId !== li.id}
                />
              ))}
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            p={4}
            gap={2}
          >
            <LoadingButton
              loading={resetVoucher.isLoading}
              onClick={onResetVoucher}
              variant="outlined"
              disabled={!selectedVoucherId}
            >
              Reset Voucher
            </LoadingButton>
            <LoadingButton
              loading={assignVoucher.isLoading}
              onClick={onSubmitVoucher}
              variant="contained"
            >
              Gunakan Voucher
            </LoadingButton>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default ResiPage;

import { useQuery } from "react-query";
import { coloadResponse } from "../../constants/Types";
import axios from "../../services/axios";

export type VoucherResponse = {
  code: 200;
  message: "Get Data Success";
  data: {
    content: {
      id: number;
      idVoucher: number;
      title: string;
      nama_ekspedisi: string;
      nama_tipe_potongan: string;
      deskripsi: string;
      kadaluwarsa: string;
      status_voucher: boolean;
    }[];
  };
};

const useVoucher = ({ nama_ekspedisi }: { nama_ekspedisi: string | null }) => {
  return useQuery(["voucher", nama_ekspedisi], async () => {
    const { data } = await axios.get<VoucherResponse>(
      `/api/getvoucher?size=1000&nama_ekspedisi=${nama_ekspedisi}`
    );
    return data.data;
  });
};

export default useVoucher;

import { DefaultOption } from '../types';

export const pesananStatus: DefaultOption[] = [
    { id: 1, nama: 'Menunggu Pembayaran' },
    { id: 15, nama: 'Menunggu Konfirmasi Generate Link' },
    { id: 2, nama: 'Menunggu Konfirmasi Pembayaran' },
    { id: 22, nama: 'Pembayaran Ditolak' },
    { id: 3, nama: 'Menunggu Cek Ketersediaan Barang' },
    { id: 4, nama: 'Menunggu Verifikasi Harga Order' },
    { id: 5, nama: 'Konfirmasi Ulang Perubahan Biaya' },
    { id: 51, nama: 'Menunggu Pembayaran Kedua' },
    { id: 52, nama: 'Menunggu Konfirmasi Pembayaran Kedua' },
    { id: 6, nama: 'Menunggu Refund' },
    { id: 7, nama: 'Batal dan Telah di-Refund' },
    { id: 8, nama: 'Proses Pembelian di China' },
    { id: 80, nama: 'Proses Pengiriman ke Gudang China' },
    { id: 81, nama: 'Barang sampai di Gudang China' },
    { id: 82, nama: 'Menunggu konfirmasi dari Agen Indonesia' },
    { id: 83, nama: 'Sudah Konfirmasi dari Agen Indonesia' },
    { id: 9, nama: 'Proses Pengiriman ke Indonesia est 35 hari' },
    { id: 10, nama: 'Barang sampai di Gudang Jakarta' },
    { id: 101, nama: 'Proses Pelunasan' },
    { id: 11, nama: 'Barang Lunas' },
    { id: 12, nama: 'Barang Dikirim ke Member' },
    { id: 0, nama: 'Order Selesai' },
];

import { Backdrop, CircularProgress } from "@mui/material";

interface ILoadingOverlayProps {
    open: boolean;
}

const LoadingOverlay = ({ open }: ILoadingOverlayProps) => {
    return (
        <Backdrop
            sx={{
                color: "primary.main",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={open}
        >
            <CircularProgress />
        </Backdrop>
    );
};

export default LoadingOverlay;

import { useQuery } from "react-query";
import axios from "../../services/axios";
import { ResiParams, InvoiceResponse } from "../../types";
import qs from "query-string";

const useGetInvoice = (params: ResiParams) => {
  const endpoint = qs.stringifyUrl({
    url: "/api/getinvoices",
    query: params,
  });
  return useQuery(["getinvoices", { ...params }], () =>
    axios.get<InvoiceResponse>(endpoint).then((res) => res.data.data)
  );
};

export default useGetInvoice;

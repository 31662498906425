import * as React from "react";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Container,
  IconButton,
  Stack,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  LogoutOutlined,
  Receipt,
  ReceiptOutlined,
  Share,
  WhatsApp,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useAuth } from "../contexts/authContext";
import toRibuan from "../utils/toRibuan";
import { CashSync, VectorLink } from "mdi-material-ui";
import useGetInvoice from "../services/queries/useGetInvoice";
import { ResiParams } from "../types";

interface Props {
  children: React.ReactNode;
}

export default function PageLayout({ children }: Props) {
  const { logout, isLoading, user } = useAuth();
  const theme = useTheme();
  const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isLaptopScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const initialCountParams: ResiParams = {
    size: 10000,
    page: 1,
    status: "1,2,3",
  };
  const [resiCountParams] = useState<ResiParams>(initialCountParams);

  const resiDataCount = useGetInvoice(resiCountParams);
  const resiCount = resiDataCount.data?.totalElements ?? 0;

  const location = useLocation();
  let history = useHistory();

  const drawerWidth = 190;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  history.listen(() => {
    setMobileOpen(false);
  });

  const laptopDrawer = (
    // <div>
    <Stack flex={1} direction="column" alignItems="flex-start" marginY={2}>
      <Box width="100%">
        <Box
          paddingY={2}
          mb={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="/images/antara-logo.png"
            width="150px"
            height="36px"
            style={{
              objectFit: "contain",
            }}
            alt="logo"
          />
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          marginBottom={6}
        >
          {/* <Link to='/pengaturan'> */}
          <Stack direction="column" spacing={1} alignItems="center">
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: "primary.main",
                  width: 50,
                  height: 50,
                }}
                alt={user?.nama}
                src={user?.nama}
              />
            </Badge>
            <Box>
              <Stack spacing={1} alignItems="center">
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  textAlign="center"
                >
                  {user?.nama}
                </Typography>
                <Chip
                  label={"Member"}
                  size="small"
                  sx={{
                    backgroundColor: "#45A779",
                    color: "#fff",
                  }}
                />
              </Stack>
            </Box>
          </Stack>
          {/* </Link> */}
        </Box>
        <Stack direction="column" spacing="13px" alignItems="center">
          <ListItem
            component={Link}
            to="/resi"
            selected={location.pathname === "/resi"}
            sx={{
              width: 80,
              height: 80,
              padding: 0,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 1,
            }}
          >
            <Stack direction="column" spacing="10px" alignItems="center">
              <AssignmentOutlinedIcon
                sx={{ fontSize: "24px", color: "#363845" }}
              />
              <Typography fontSize="12px" color="#363845">
                Resi Saya
              </Typography>
            </Stack>
          </ListItem>
          <ListItem
            component={Link}
            to="/invoice"
            selected={location.pathname === "/invoice"}
            sx={{
              width: 80,
              height: 80,
              padding: 0,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 1,
            }}
          >
            <Stack direction="column" spacing="10px" alignItems="center">
              <Badge badgeContent={resiCount ?? 1} color="primary">
                <ReceiptOutlined sx={{ fontSize: "24px", color: "#363845" }} />
              </Badge>
              <Typography fontSize="12px" color="#363845">
                Invoice
              </Typography>
            </Stack>
          </ListItem>
          <ListItem
            component={Link}
            to="/pesanan"
            selected={location.pathname === "/pesanan"}
            sx={{
              width: 80,
              height: 80,
              padding: 0,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 1,
            }}
          >
            <Stack direction="column" spacing="10px" alignItems="center">
              <ShoppingCartOutlinedIcon
                sx={{ fontSize: "24px", color: "#363845" }}
              />
              <Typography fontSize="12px" color="#363845">
                Pesanan
              </Typography>
            </Stack>
          </ListItem>
          <ListItem
            component={Link}
            to="/jasa-transfer"
            selected={location.pathname === "/jasa-transfer"}
            sx={{
              width: 80,
              height: 80,
              padding: 0,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 1,
            }}
          >
            <Stack direction="column" spacing="10px" alignItems="center">
              <CreditScoreOutlinedIcon
                sx={{ fontSize: "24px", color: "#363845" }}
              />
              <Typography fontSize="12px" color="#363845">
                Jasa Transfer
              </Typography>
            </Stack>
          </ListItem>
          <ListItem
            component={Link}
            to="/point-royalty"
            selected={location.pathname === "/point-royalty"}
            sx={{
              width: 80,
              height: 80,
              padding: 0,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 1,
            }}
          >
            <Stack direction="column" spacing="10px" alignItems="center">
              <AccountBalanceWalletOutlinedIcon
                sx={{ fontSize: "24px", color: "#363845" }}
              />
              <Typography fontSize="12px" color="#363845">
                Point Royalty
              </Typography>
            </Stack>
          </ListItem>
        </Stack>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        flexGrow={1}
      />
      <Stack padding={2} spacing={2} alignItems="center" width="100%">
        <Button
          variant="text"
          color="greyc4"
          sx={{ textTransform: "none", width: "100%" }}
          onClick={logout}
          startIcon={isLoading ? <CircularProgress /> : <LogoutOutlined />}
          disabled={isLoading}
        >
          Keluar
        </Button>
        <Button
          href={`https://wa.me/send?phone=${user?.nomor_wa ?? ""}`}
          target="_blank"
          rel="noreferrer"
          variant="outlined"
          startIcon={<WhatsApp />}
          color="whatsapp"
          sx={{ textTransform: "none" }}
        >
          Hubungi
        </Button>
      </Stack>
      {/* <Box width="100%" display="flex" flexDirection="column" paddingX={2}>
                <Typography fontSize="12px" color="#999999">
                    Powered by
                </Typography>
                <MaterialLink
                    target="_blank"
                    href="https://nore.web.id/"
                    underline="hover"
                    display="inline"
                    color="#999999"
                >
                    <Typography fontSize="15px" color="#999999" fontWeight="bold">
                        {`Nore Inovasi © ${thisYear}`}
                    </Typography>
                </MaterialLink>
            </Box> */}
    </Stack>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;
  // const urlParams = new URLSearchParams(queryString);
  // const queryUkmId = urlParams.get("redirect");

  // if (isLoadingUkm || isLoadingUser) {
  //     return (
  //         <Box position="relative" height="100vh">
  //             <Spinner />
  //         </Box>
  //     );
  // }

  return (
    <Box
      sx={{
        display: "flex",
        paddingTop: isPhoneScreen
          ? "56px"
          : isLaptopScreen
          ? undefined
          : "64px",
      }}
    >
      <AppBar
        sx={{
          display: { xs: "block", lg: "none" },
          width: { lg: `calc(100% - 190px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {user?.nama ?? ""}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { lg: 190 }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", lg: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {laptopDrawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "block",
            },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: 190,
            },
          }}
          open
        >
          {laptopDrawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1 }}>
        {/* <Box
                    sx={{
                        // backgroundColor: '#182F74',
                        backgroundColor: "#ffffff",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                >
                    <Container maxWidth="lg">
                        <Stack
                            direction="row"
                            spacing={2}
                            paddingY={1}
                            alignItems="center"
                        >
                            <Typography fontWeight="500">Total invoice</Typography>
                            {user?.total_invoice.map((li, idx) => (
                                <Box
                                    key={idx.toString()}
                                    component="a"
                                    target="_blank"
                                    href={li.redirect}
                                >
                                    <Chip
                                        label={`Rp ${toRibuan(li.nominal)}`}
                                        color="secondary"
                                        sx={{
                                            borderRadius: 2,
                                            ":hover": {
                                                backgroundColor: "rgba(255,0,0,0.05)",
                                            },
                                        }}
                                        icon={<CashSync fontSize="small" />}
                                        variant="outlined"
                                    />
                                </Box>
                            ))}
                            {user?.total_invoice.length === 0 && (
                                <Typography color="#c5c5c5" variant="caption">
                                    Tidak ada invoice tertagih
                                </Typography>
                            )}
                        </Stack>
                    </Container>
                </Box> */}
        <Container maxWidth="xl">{children}</Container>
      </Box>
    </Box>
  );
}

import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="none" {...props}>
        <g filter="url(#a)">
            <path
                fill={props.fill ?? "#ECCB64"}
                d="m24 28.95 3.85 2.3c.367.233.725.225 1.074-.024.35-.25.475-.591.376-1.026l-1-4.35 3.4-2.95c.333-.3.433-.658.3-1.074-.133-.416-.433-.641-.9-.676l-4.45-.35-1.75-4.15c-.167-.4-.467-.6-.9-.6s-.733.2-.9.6l-1.75 4.15-4.45.35c-.467.033-.767.259-.9.676-.133.417-.033.775.3 1.074l3.4 2.95-1 4.35c-.1.433.025.775.376 1.026.35.25.709.259 1.074.024l3.85-2.3ZM17.3 40H12c-1.1 0-2.042-.392-2.826-1.176C8.39 38.04 7.999 37.099 8 36v-5.3l-3.85-3.9c-.367-.4-.65-.841-.85-1.324-.2-.483-.3-.975-.3-1.476 0-.5.1-.992.3-1.476.2-.484.483-.925.85-1.324L8 17.3V12c0-1.1.392-2.042 1.176-2.826C9.96 8.39 10.901 7.999 12 8h5.3l3.9-3.85c.4-.367.842-.65 1.326-.85.484-.2.975-.3 1.474-.3.5 0 .992.1 1.476.3.484.2.925.483 1.324.85L30.7 8H36c1.1 0 2.042.392 2.826 1.176C39.61 9.96 40.001 10.901 40 12v5.3l3.85 3.9c.367.4.65.842.85 1.326.2.484.3.975.3 1.474 0 .5-.1.992-.3 1.476-.2.484-.483.925-.85 1.324L40 30.7V36c0 1.1-.392 2.042-1.176 2.826C38.04 39.61 37.099 40.001 36 40h-5.3l-3.9 3.85c-.4.367-.841.65-1.324.85-.483.2-.975.3-1.476.3-.5 0-.991-.1-1.474-.3a4.522 4.522 0 0 1-1.326-.85L17.3 40Z"
            />
            <path
                fill="url(#b)"
                fillOpacity={0.08}
                d="m24 28.95 3.85 2.3c.367.233.725.225 1.074-.024.35-.25.475-.591.376-1.026l-1-4.35 3.4-2.95c.333-.3.433-.658.3-1.074-.133-.416-.433-.641-.9-.676l-4.45-.35-1.75-4.15c-.167-.4-.467-.6-.9-.6s-.733.2-.9.6l-1.75 4.15-4.45.35c-.467.033-.767.259-.9.676-.133.417-.033.775.3 1.074l3.4 2.95-1 4.35c-.1.433.025.775.376 1.026.35.25.709.259 1.074.024l3.85-2.3ZM17.3 40H12c-1.1 0-2.042-.392-2.826-1.176C8.39 38.04 7.999 37.099 8 36v-5.3l-3.85-3.9c-.367-.4-.65-.841-.85-1.324-.2-.483-.3-.975-.3-1.476 0-.5.1-.992.3-1.476.2-.484.483-.925.85-1.324L8 17.3V12c0-1.1.392-2.042 1.176-2.826C9.96 8.39 10.901 7.999 12 8h5.3l3.9-3.85c.4-.367.842-.65 1.326-.85.484-.2.975-.3 1.474-.3.5 0 .992.1 1.476.3.484.2.925.483 1.324.85L30.7 8H36c1.1 0 2.042.392 2.826 1.176C39.61 9.96 40.001 10.901 40 12v5.3l3.85 3.9c.367.4.65.842.85 1.326.2.484.3.975.3 1.474 0 .5-.1.992-.3 1.476-.2.484-.483.925-.85 1.324L40 30.7V36c0 1.1-.392 2.042-1.176 2.826C38.04 39.61 37.099 40.001 36 40h-5.3l-3.9 3.85c-.4.367-.841.65-1.324.85-.483.2-.975.3-1.476.3-.5 0-.991-.1-1.474-.3a4.522 4.522 0 0 1-1.326-.85L17.3 40Z"
            />
        </g>
        <defs>
            <linearGradient
                id="b"
                x1={4}
                x2={46}
                y1={4}
                y2={46}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#717171" />
                <stop offset={0.1} stopColor="#fff" />
                <stop offset={0.2} stopColor="#717171" />
                <stop offset={0.3} stopColor="#fff" />
                <stop offset={0.4} stopColor="#717171" />
                <stop offset={0.5} stopColor="#fff" />
                <stop offset={0.6} stopColor="#717171" />
                <stop offset={0.7} stopColor="#fff" />
                <stop offset={0.8} stopColor="#717171" />
                <stop offset={0.9} stopColor="#fff" />
                <stop offset={1} stopColor="#717171" />
            </linearGradient>
            <filter
                id="a"
                width={50}
                height={50}
                x={-1}
                y={-1}
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dx={-1} dy={-1} />
                <feGaussianBlur stdDeviation={1} />
                <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                <feBlend in2="shape" result="effect1_innerShadow_4829_6554" />
                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dx={1} dy={1} />
                <feGaussianBlur stdDeviation={1} />
                <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
                <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0" />
                <feBlend
                    in2="effect1_innerShadow_4829_6554"
                    result="effect2_innerShadow_4829_6554"
                />
            </filter>
        </defs>
    </svg>
);
export default SvgComponent;

import { Box, Chip, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { TimerOutline } from "mdi-material-ui";
import React, { useEffect } from "react";

type VoucherCardProps = {
  title: string;
  deskripsi: string;
  kadaluwarsa: string;
  nama_tipe_potongan: string;
  status_voucher: boolean;
  nama_ekspedisi: string;
  onClickDetail?: () => void;
  onClick?: () => void;
  selected?: boolean;
  viewOnly?: boolean;
};

export default function VoucherCard({
  deskripsi,
  title,
  onClickDetail,
  kadaluwarsa,
  nama_ekspedisi,
  status_voucher,
  nama_tipe_potongan,
  onClick,
  selected,
  viewOnly,
}: VoucherCardProps) {
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <Box
      sx={{
        cursor: !!onClick ? "pointer" : "default",
        width: "100%",
      }}
      onClick={handleClick}
    >
      <Stack
        paddingX={2}
        paddingY={1}
        // direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight: "160px",
          borderRadius: "4px",
          border: selected
            ? "1px solid #182F74"
            : viewOnly
            ? "1px solid #C5C5C5"
            : "1px solid #C5C5C5",
          backgroundColor: selected
            ? "#eff3ff"
            : viewOnly
            ? "#f4f4f4"
            : "#ffffff",
        }}
      >
        <Stack spacing={2}>
          <Typography variant="h6" fontWeight="700">
            {title}
          </Typography>
          <Typography
            variant="body1"
            color="#6D6D6D"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              display: "-webkit-box",
              "-webkit-line-clamp": "2",
              "-webkit-box-orient": "vertical",
            }}
          >
            {deskripsi}
          </Typography>

          <Stack direction="row" alignItems="center" spacing={1}>
            <Chip label={nama_ekspedisi} color="primary" variant="outlined" />
            <Chip
              label={nama_tipe_potongan}
              color="primary"
              variant="outlined"
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <TimerOutline htmlColor="#9E9E9E" />
            <Typography variant="body2" color="#9E9E9E">
              Berakhir{" "}
              <Box
                component="span"
                sx={{ color: "#6D6D6D", fontWeight: "bold" }}
              >
                {kadaluwarsa}
              </Box>
            </Typography>
          </Stack>
          {onClickDetail && (
            <Box component="a" href="#" onClick={onClickDetail}>
              <Typography
                variant="body2"
                fontWeight="500"
                color="#1D69C7"
                sx={{ textDecoration: "underline !important" }}
              >
                Lihat detail
              </Typography>
            </Box>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}

import React from "react";
import Routes from "./routes/Routes";
import AppProvider from "./contexts";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./App.css";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 3,
        },
    },
});

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <CookiesProvider>
                <AppProvider>
                    <Routes />
                </AppProvider>
            </CookiesProvider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
    );
}

export default App;

import { Container, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import React, { ReactNode } from "react";

type MainContainerType = {
    children: ReactNode;
    title: string;
    subtitle: string;
};

export default function MainContainer({ children, title, subtitle }: MainContainerType) {
    return (
        <Container sx={{ padding: "24px 12px" }} fixed>
            <Stack spacing={1} marginBottom={3}>
                <Typography variant="h4" fontWeight="bold">
                    {title}
                </Typography>
                <Typography variant="body1">{subtitle}</Typography>
            </Stack>
            {children}
        </Container>
    );
}

import { Cookies } from "react-cookie";
import defaultAxios, { AxiosError } from "axios";
import React, {
    createContext,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { tokenKey } from "../constants/common";
import {
    AuthState,
    CheckToken,
    DefaultResponse,
    LoginBody,
    LoginResponse,
    User,
} from "../types";
import axios from "../services/axios";
import { useQueryClient } from "react-query";
import Swal from "sweetalert2";
import { getDomainWithoutSubdomain } from "../utils/getDomainWithoutSubdomain";

interface IAuthProviderProps {
    children: ReactNode;
}

const AuthContext = createContext<AuthState | undefined>(undefined);

const AuthProvider = ({ children }: IAuthProviderProps) => {
    const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
    // const [isRegistered, setRegistered] = useState<boolean>(false);
    const [isPreparingApp, setPreparingApp] = useState<boolean>(true);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useState<User>();
    const initialCount = Number(localStorage.getItem("loginCount")) ?? 0;
    const [loginCount, setLoginCount] = useState<number>(initialCount);
    const cookies = useMemo(() => new Cookies(), []);
    const queryClient = useQueryClient();

    // const deviceId = (await Notifications.getDevicePushTokenAsync()).data;

    // const handleSetDeviceId = useCallback(async () => {
    //     const deviceId = (await Notifications.getDevicePushTokenAsync()).data;
    //     try {
    //         await AsyncStorage.setItem(deviceIdKey, deviceId);
    //     } catch (error) {
    //         throw error;
    //     }
    // }, [AsyncStorage.setItem]);
    // console.log(deviceId);

    const handleRemoveToken = useCallback(async () => {
        try {
            if (process.env.NODE_ENV === "production") {
                const domainUrl = getDomainWithoutSubdomain(window.location.href);
                await cookies.remove(tokenKey, {
                    path: "/",
                    domain: `.${domainUrl}`,
                });
            } else {
                await cookies.remove(tokenKey);
            }
        } catch (error) {
            throw error;
        }
    }, [cookies]);

    const handleErrorResponse = useCallback(
        (error: unknown) => {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<any>;
                if (serverError && serverError.response) {
                    console.log(`serverError`, serverError.response);
                    if (serverError.response!.status === 400) {
                        Swal.fire({
                            title: "Terjadi Kesalahan!",
                            text: `${serverError.response?.data?.data}`,
                            icon: "error",
                            confirmButtonColor: "#45A779",
                            customClass: {
                                container: "my-swal",
                            },
                        });
                        // setLoginCount(serverError.response.data.data.jumlah_gagal);
                        // localStorage.setItem(
                        //     "loginCount",
                        //     String(serverError.response.data.data.jumlah_gagal)
                        // );
                        // console.log(
                        //     "",
                        //     `${serverError.response.data.data.jumlah_gagal}`,
                        //     [{ text: "OK" }]
                        // );
                    }

                    if (serverError.response!.status === 422) {
                        Swal.fire({
                            title: "Terjadi Kesalahan!",
                            text: `Ada error validasi`,
                            icon: "error",
                            confirmButtonColor: "#45A779",
                            customClass: {
                                container: "my-swal",
                            },
                        });
                        console.log("", `${serverError.response.data?.message}`, [
                            { text: "OK" },
                        ]);
                    }

                    if (serverError.response!.status === 403) {
                        // Alert.alert("", `Maaf, akun Anda tidak terautentikasi.`,
                        // console.log("", `Maaf, akun Anda tidak terautentikasi.`, [
                        //     { text: "OK" },
                        // ]);
                        Swal.fire({
                            title: "Terjadi Kesalahan!",
                            text: `${serverError.response.data?.message}`,
                            icon: "error",
                            confirmButtonColor: "#45A779",
                            customClass: {
                                container: "my-swal",
                            },
                        });
                        console.log("", `${serverError.response.data.data}`, [
                            { text: "OK" },
                        ]);
                    }
                } else {
                    // Alert.alert("", `Terjadi kesalahan! Silahkan coba lagi.`,
                    console.error("", `Terjadi kesalahan! Silahkan coba lagi.`, [
                        { text: "OK" },
                    ]);
                }
            }
        },
        [loginCount]
    );

    // useEffect(() => {
    //     console.log("Login count: ", loginCount);
    // }, [loginCount]);

    const checkToken = useCallback(
        async (token: string) => {
            try {
                const { data } = await axios.post<CheckToken>(`/api/cektoken`, {
                    token: token,
                });

                if (token && data.code === 200) {
                    const { data: waAdmin } = await axios.get<
                        DefaultResponse<{
                            nomor_wa: string | null;
                        }>
                    >("/api/wa-marketing", {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    setUser({ ...data.data, nomor_wa: waAdmin.data.nomor_wa });

                    setAuthenticated(true);
                    setLoading(false);
                }
                setLoading(false);
            } catch (error) {
                handleErrorResponse(error);
                setAuthenticated(false);
                handleRemoveToken();
                queryClient.clear();
            }
        },
        [handleErrorResponse, handleRemoveToken, queryClient]
    );

    const loadResourcesAndDataAsync = useCallback(async () => {
        try {
            const token = await cookies.get(tokenKey);
            if (token) {
                await checkToken(token);
            }
        } catch (e) {
            console.warn(e);
        } finally {
            setPreparingApp(false);
        }
    }, [checkToken, cookies]);

    useEffect(() => {
        loadResourcesAndDataAsync();
    }, [loadResourcesAndDataAsync]);

    const handleSetToken = useCallback(
        async (token: string | null) => {
            try {
                if (process.env.NODE_ENV === "production") {
                    const domainUrl = getDomainWithoutSubdomain(window.location.href);
                    cookies.set(tokenKey, token, {
                        path: "/",
                        domain: `.${domainUrl}`,
                    });
                } else {
                    await cookies.set(tokenKey, token, {
                        path: "/",
                    });
                }
            } catch (error) {
                throw error;
            }
        },
        [cookies]
    );

    // const handleRegistered = () => {
    //     setRegistered(true);
    // };

    // const handleAuthenticated = (value: boolean) => {
    //     setAuthenticated(value);
    // };

    const login = useCallback(
        async (values: LoginBody) => {
            setLoading(true);
            try {
                const { data } = await axios.post<LoginResponse>("/api/mlogin", {
                    username: values.email,
                    password: values.password,
                    coload_id: 1,
                });
                console.log(`data`, data);

                if (data.code === 200) {
                    const { data: waAdmin } = await axios.get<
                        DefaultResponse<{
                            nomor_wa: string | null;
                        }>
                    >("/api/wa-marketing", {
                        headers: {
                            Authorization: `Bearer ${data.data.access_token}`,
                        },
                    });

                    setUser({ ...data.data, nomor_wa: waAdmin.data.nomor_wa });

                    setAuthenticated(true);
                    handleSetToken(data.data.access_token);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                handleErrorResponse(error);
            }
        },
        [handleErrorResponse, handleSetToken]
    );

    // const resetLoginCount = () => {
    //     setLoginCount(0);
    //     localStorage.removeItem("loginCount");
    // };

    // const register = useCallback(
    //     async (values: RegistrasiForm) => {
    //         setLoading(true);
    //         try {
    //             const formData = new FormData();
    //             formData.append("nama", values.nama);
    //             formData.append("username", values.username);
    //             formData.append("email", values.email);
    //             formData.append("no_telp", values.no_telp);
    //             formData.append("password", values.password);
    //             formData.append("role", "10");
    //             // formData.append("alamat", "");
    //             // formData.append("gambar", "");
    //             const { data } = await axios.post<RegistrasiResponse>(
    //                 "/api/register",
    //                 formData,
    //                 {
    //                     headers: {
    //                         "Content-Type": "multipart/form-data",
    //                     },
    //                 }
    //             );
    //             console.log(`data register`, data);

    //             if (data.code === 200) {
    //                 setAuthenticated(true);
    //                 handleSetToken(data.data.token);
    //                 // checkToken(data.data.token);
    //             }
    //             setLoading(false);
    //         } catch (error) {
    //             setLoading(false);
    //             handleErrorResponse(error);
    //         }
    //     },
    //     [handleErrorResponse, handleSetToken]
    // );

    const logout = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await axios.get<DefaultResponse<null>>("/api/mlogout");
            if (data.code === 200) {
                setAuthenticated(false);
                handleRemoveToken();
                queryClient.clear();
            }
            setLoading(false);
        } catch (error) {
            handleErrorResponse(error);
            setLoading(false);
        }
    }, [handleErrorResponse, handleRemoveToken, queryClient]);

    const value = useMemo(
        () => ({
            isAuthenticated,
            isLoading,
            checkToken,
            login,
            user,
            logout,
        }),
        [isAuthenticated, isLoading, checkToken, login, user, logout]
    );

    if (isPreparingApp) return null;

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within a AuthProvider");
    }
    return context;
};

export { AuthContext, AuthProvider, useAuth };

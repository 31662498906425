import { DefaultOption, DefaultResponse } from "../../types";
import axios from "../axios";
import { useQuery } from "react-query";

export default function useGetStatusResi() {
    return useQuery(
        "resi-status",
        () =>
            axios
                .get<DefaultResponse<DefaultOption[]>>("/api/getstatusresi")
                .then((res) => res.data.data),
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        }
    );
}

import { useEffect, useMemo, useState } from "react";
import useGetPesanan from "../../services/queries/useGetPesanan";
import { DefaultOption, ResiParams } from "../../types";
import { DataGrid, GridColDef, GridRowId, GridRowsProp } from "@mui/x-data-grid";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import MainContainer from "../../components/MainContainer/MainContainer";
import {
    Stack,
    Button,
    Box,
    Container,
    CircularProgress,
    Input,
    FormHelperText,
    InputLabel,
    FormControl,
    OutlinedInput,
    IconButton,
    Autocomplete,
    TextField,
    Chip,
    Modal,
    Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import DeleteIcon from "@mui/icons-material/Delete";
import PinDropIcon from "@mui/icons-material/PinDrop";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import dayjs, { Dayjs } from "dayjs";
import useGetStatusResi from "../../services/queries/useGetStatusResi";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RemoveIcon from "@mui/icons-material/Remove";
import useGetDetailResi from "../../services/queries/useGetDetailResi";
import { Close, OpenInNew } from "@mui/icons-material";
import toRibuan from "../../utils/toRibuan";
import { pesananStatus } from "../../constants/pesananStatus";
import { apiUrl } from "../../constants/common";
import axios from "../../services/axios";

const PesananPage = () => {
    const initialParams: ResiParams = {
        size: 10,
        page: 1,
    };

    const [resiParams, setResiParams] = useState<ResiParams>(initialParams);
    const [searchValue, setSearchValue] = useState("");
    const [status, setStatus] = useState<DefaultOption[]>([]);
    const [date, setDate] = useState<Dayjs | null>(null);
    const [dateAkhir, setDateAkhir] = useState<Dayjs | null>(null);
    const [selectedResi, setSelectedResi] = useState<number | undefined>(undefined);

    const resiData = useGetPesanan(resiParams);
    const resi = resiData.data?.content.map((li, idx) => ({
        nomor: idx + 1,
        action_id: li.id,
        ...li,
    }));
    const rowCount = resiData.data?.totalElements ?? 0;

    // const resiStatusData = useGetStatusResi();
    const resiStatus = pesananStatus;
    const selectedData = useMemo(() => {
        if (!resi || !selectedResi) return null;

        const result = resi.find((li) => li.id === selectedResi);

        return {
            ...result,
            detail_pesanan:
                result?.detail_pesanan.map((li, idx) => ({
                    nomor: idx + 1,
                    ...li,
                })) ?? [],
        };
    }, [resi, selectedResi]);

    const handleSearch = () => {
        setResiParams((prev) => ({
            ...prev,
            page: 1,
            search: searchValue,
        }));
    };

    const handleClearFilter = () => {
        setResiParams(initialParams);
        setDate(null);
        setDateAkhir(null);
        setSearchValue("");
        setStatus([]);
    };

    const handleDownload = (link: string, filename: string) => {
        axios({
            url: link, //your url
            method: "GET",
            responseType: "blob", // important
        })
            .then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // const contentDisposition =
                //     response?.headers["Content-Disposition"] ??
                //     response?.headers["content-disposition"] ??
                //     null;

                // console.log("contentDisposition", contentDisposition);

                // const filename = contentDisposition?.split('"')[1];

                // create "a" HTML element with href to file & click
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", filename); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((err) => alert(JSON.stringify(err.response, null, 2)));
    };

    useEffect(() => {
        if (status.length === 0) {
            setResiParams((prev) => ({
                ...prev,
                page: 1,
                status: undefined,
            }));
            return;
        }

        setResiParams((prev) => ({
            ...prev,
            page: 1,
            status: status.map((li) => li.id).join(","),
        }));
    }, [status]);

    useEffect(() => {
        if (!date || !dateAkhir) return;

        setResiParams((prev) => ({
            ...prev,
            page: 1,
            tanggal_awal: date.format("YYYY-MM-DD"),
            tanggal_akhir: dateAkhir.format("YYYY-MM-DD"),
        }));
    }, [date, dateAkhir]);

    const rows: GridRowsProp = resi ?? [];

    const columns: GridColDef[] = [
        {
            field: "nomor",
            headerName: "No",
            headerAlign: "center",
            align: "center",
            maxWidth: 100,
            headerClassName: "header-table",
        },
        {
            field: "no_invoice",
            headerName: "No Invoice",
            minWidth: 150,
            flex: 1,
            headerClassName: "header-table",
        },
        {
            field: "tanggal_invoice",
            headerName: "Tanggal",
            headerAlign: "center",
            align: "center",
            minWidth: 150,
            headerClassName: "header-table",
            renderCell(params) {
                return dayjs(params.value).format("DD MMM YYYY");
            },
        },
        {
            field: "total_bayar",
            headerName: "Total Bayar",
            headerAlign: "center",
            align: "center",
            minWidth: 200,
            headerClassName: "header-table",
            renderCell(params) {
                return `Rp ${toRibuan(params.value)}`;
            },
        },
        {
            field: "status",
            headerName: "Status Resi",
            headerAlign: "center",
            align: "center",
            minWidth: 300,
            headerClassName: "header-table",
            renderCell(params) {
                return (
                    <Chip label={params?.value.nama} color="primary" variant="outlined" />
                );
            },
        },
        {
            field: "action_id",
            headerName: "Aksi",
            headerAlign: "center",
            align: "center",
            headerClassName: "header-table",
            maxWidth: 160,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            size="small"
                            color="buttongreen"
                            disableElevation
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                            onClick={() => setSelectedResi(params.value)}
                        >
                            <VisibilityIcon sx={{ width: "16px", height: "16px" }} />
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            color="buttonyellow"
                            disableElevation
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                            onClick={() =>
                                handleDownload(
                                    `/api/my-order/${params.value}/download`,
                                    `MyOrder_${params.row.no_invoice}.xls`
                                )
                            }
                        >
                            <PrintIcon sx={{ width: "16px", height: "16px" }} />
                        </Button>
                    </Stack>
                );
            },
        },
    ];

    const columnsDetail: GridColDef[] = [
        {
            field: "nomor",
            headerName: "No",
            headerAlign: "center",
            align: "center",
            maxWidth: 100,
            headerClassName: "header-table",
        },
        {
            field: "item",
            headerName: "Barang",
            minWidth: 200,
            flex: 1,
            headerClassName: "header-table",
        },
        {
            field: "harga",
            headerName: "Harga (¥)",
            headerAlign: "center",
            align: "center",
            minWidth: 150,
            headerClassName: "header-table",
            renderCell(params) {
                return `Rp ${toRibuan(params.value)}`;
            },
        },
        {
            field: "qty",
            headerName: "Qty",
            headerAlign: "center",
            align: "center",
            minWidth: 100,
            headerClassName: "header-table",
            renderCell(params) {
                return toRibuan(params.value);
            },
        },
        {
            field: "pengiriman_cn",
            headerName: "Pengiriman CN (¥)",
            headerAlign: "center",
            align: "center",
            minWidth: 150,
            headerClassName: "header-table",
        },
        {
            field: "keterangan",
            headerName: "Keterangan",
            headerAlign: "center",
            align: "center",
            minWidth: 200,
            headerClassName: "header-table",
            renderCell(params) {
                return params.value ?? "-";
            },
        },
        {
            field: "link_tambah_barang",
            headerName: "Aksi",
            headerAlign: "center",
            align: "center",
            headerClassName: "header-table",
            minWidth: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <a href={params.value} target="_blank" rel="noreferrer">
                        <Button
                            variant="contained"
                            size="small"
                            color="buttonblue"
                            disableElevation
                        >
                            <OpenInNew sx={{ width: "16px", height: "16px" }} /> Tambah
                            Barang
                        </Button>
                    </a>
                );
            },
        },
    ];

    function truncate(str: string, n: number) {
        return str.length > n ? str.slice(0, n - 1) + `...` : str;
    }

    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 1000,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
    };

    // if (resiData.isLoading) {
    //     return (
    //         <Stack height="80vh" alignItems="center" justifyContent="center">
    //             <CircularProgress />
    //         </Stack>
    //     );
    // }

    return (
        <MainContainer title="Pesanan Saya" subtitle="Daftar semua pesanan saya">
            <Stack paddingY={3} spacing={3}>
                <Stack direction="row" gap={3}>
                    <OutlinedInput
                        name="search"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.code === "Enter") {
                                handleSearch();
                            }
                        }}
                        sx={{ width: "80%" }}
                        placeholder="Cari Berdasarkan Nomor Invoice"
                        endAdornment={
                            <IconButton onClick={handleSearch}>
                                <SearchIcon color="secondary" />
                            </IconButton>
                        }
                    />
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleClearFilter}
                    >
                        Reset Filter
                    </Button>
                </Stack>
                <Stack direction="row" gap={3}>
                    <Autocomplete
                        disablePortal
                        multiple
                        options={resiStatus ?? []}
                        value={status}
                        onChange={(_, newValue) => {
                            setStatus(newValue);
                        }}
                        getOptionLabel={(option: DefaultOption) => option.nama}
                        sx={{ width: "45%" }}
                        renderInput={(params) => (
                            <TextField {...params} label="Pilih Status Pesanan" />
                        )}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={truncate(option.nama, 10)}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        limitTags={3}
                    />
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={3}
                        sx={{ width: "50%" }}
                    >
                        <DatePicker
                            sx={{ width: "48%" }}
                            label="Pilih Tanggal Awal"
                            value={date}
                            onChange={(value) => setDate(value)}
                        />
                        <RemoveIcon />
                        <DatePicker
                            sx={{ width: "48%" }}
                            label="Pilih Tanggal Akhir"
                            value={dateAkhir}
                            onChange={(value) => setDateAkhir(value)}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <Box>
                <DataGrid
                    autoHeight
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                page: initialParams.page,
                                pageSize: initialParams.size,
                            },
                        },
                    }}
                    checkboxSelection={false}
                    getRowId={(row) => row.id}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    paginationMode="server"
                    rowCount={rowCount}
                    loading={resiData.isLoading}
                    pageSizeOptions={[10]}
                    paginationModel={{
                        pageSize: resiParams.size,
                        page: resiParams.page - 1,
                    }}
                    onPaginationModelChange={({ page, pageSize }) => {
                        setResiParams((prev) => ({
                            ...prev,
                            page: page + 1,
                            size: pageSize,
                        }));
                    }}
                    density="comfortable"
                />
            </Box>
            <Modal open={!!selectedResi} onClose={() => setSelectedResi(undefined)}>
                <Box sx={style}>
                    <>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Stack>
                                <Typography variant="h5" fontWeight="bold">
                                    {selectedData?.no_invoice ?? "-"}
                                </Typography>
                                <Typography variant="body2">
                                    {dayjs(selectedData?.tanggal_invoice).format(
                                        "DD MMM YYYY"
                                    ) ?? "-"}
                                </Typography>
                            </Stack>
                            <IconButton onClick={() => setSelectedResi(undefined)}>
                                <Close />
                            </IconButton>
                        </Stack>
                        <Box my={2}>
                            <Chip
                                label={selectedData?.status?.nama}
                                color="primary"
                                variant="outlined"
                            />
                        </Box>
                        <DataGrid
                            autoHeight
                            rows={selectedData?.detail_pesanan ?? []}
                            columns={columnsDetail}
                            getRowId={(value) => value.nomor}
                            checkboxSelection={false}
                            disableColumnMenu
                            disableRowSelectionOnClick
                            pageSizeOptions={[5, 10, 15]}
                            density="comfortable"
                        />
                    </>
                </Box>
            </Modal>
        </MainContainer>
    );
};

type InfoDescription = {
    title: string;
    subtitle: string;
};

const InfoDescription = ({ title, subtitle }: InfoDescription) => {};

export default PesananPage;

import { useMutation, useQueryClient } from 'react-query';
import axios from '../axios';
import { AxiosError } from 'axios';

export type AssignVoucherBody = {
    no_resi: string;
    voucher_id: number;
};

export default function useAssignVoucher() {
    const queryClient = useQueryClient();
    return useMutation<any, AxiosError, AssignVoucherBody>({
        mutationFn: async (values) => {
            const { data } = await axios.post(
                `/api/savevoucher/${values.no_resi}/${values.voucher_id}`
            );

            return data;
        },
        onSuccess() {
            queryClient.invalidateQueries(['voucher']);
            queryClient.invalidateQueries(['getresi']);
        },
    });
}

import { useMutation, useQueryClient } from 'react-query';
import axios from '../axios';
import { AxiosError } from 'axios';

export type ResetVoucherBody = {
    no_resi: string;
};

export default function useResetVoucher() {
    const queryClient = useQueryClient();
    return useMutation<any, AxiosError, ResetVoucherBody>({
        mutationFn: async (values) => {
            const { data } = await axios.post(
                `/api/resetvoucher/${values.no_resi}`
            );

            return data;
        },
        onSuccess() {
            queryClient.invalidateQueries(['voucher']);
            queryClient.invalidateQueries(['getresi']);
        },
    });
}

import { useQuery } from "react-query";
import { coloadResponse } from "../../constants/Types";
import axios from "../../services/axios";

export type LoyaltyResponse = {
  code: number;
  message: string;
  data: {
    status: string;
    nextStatus: string;
    totalTransaksi: number;
    nextTransaksi: number;
    tanggalReset: string;
  };
};

const useLoyalty = () => {
  return useQuery(["loyalty"], async () => {
    const { data } = await axios.get<LoyaltyResponse>(`/api/getloyalty`);
    return data.data;
  });
};

export default useLoyalty;

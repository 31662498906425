import { useQuery } from "react-query";
import { JasaTransferResponse } from "../../constants/Types";
import axios from "../../services/axios";
import qs from "query-string";

interface JasaTransferProps {
    size: number;
    page: number;
    status?: string;
    tanggal_awal?: string;
    tanggal_akhir?: string;
}

type GetJasaTransfer = {
    code: number;
    message: string;
    data: JasaTransferResponse;
};

const useGetJasaTransfer = (props: JasaTransferProps) => {
    const params = qs.stringify(props, {
        skipEmptyString: true,
        skipNull: true,
    });

    return useQuery(["jasaTransfer", { ...props }], () =>
        axios
            .get<GetJasaTransfer>(`/api/getjasatransfer?${params}`)
            .then((res) => res.data.data)
    );
};

export default useGetJasaTransfer;

import { useEffect, useMemo, useState } from "react";
import useGetInvoice from "../../services/queries/useGetInvoice";
import { DefaultOption, InvoiceContent, ResiParams } from "../../types";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import MainContainer from "../../components/MainContainer/MainContainer";
import {
    Stack,
    Button,
    Box,
    OutlinedInput,
    IconButton,
    Autocomplete,
    TextField,
    Chip,
    Modal,
    Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RemoveIcon from "@mui/icons-material/Remove";
import { Close } from "@mui/icons-material";
import toRibuan from "../../utils/toRibuan";
import axios from "../../services/axios";
import useGetStatusInvoice from "../../services/queries/useGetStatusInvoice";

const InvoicePage = () => {
    const initialParams: ResiParams = {
        status: "1,2,3,4",
        size: 10,
        page: 1,
    };

    const [resiParams, setResiParams] = useState<ResiParams>(initialParams);
    const [searchValue, setSearchValue] = useState("");
    const [status, setStatus] = useState<DefaultOption[]>([]);
    const [date, setDate] = useState<Dayjs | null>(null);
    const [dateAkhir, setDateAkhir] = useState<Dayjs | null>(null);
    const [selectedInvoice, setSelectedInvoice] = useState<number | undefined>(undefined);

    const resiData = useGetInvoice(resiParams);
    const resi =
        resiData.data?.content?.map((li, idx) => ({
            nomor: idx + 1,
            action_id: li.id,
            ...li,
        })) ?? [];
    const rowCount = resiData.data?.totalElements ?? 0;

    const resiStatusData = useGetStatusInvoice();
    const resiStatus = resiStatusData.data?.filter((li) => li.nama !== "Belum Ditagih");

    const selectedData = useMemo(() => {
        if (!resi || !selectedInvoice) return null;
        const result = resi.find((li) => li.id === selectedInvoice);
        return {
            ...result,
            detail_Invoice: result ?? [],
        };
    }, [resi, selectedInvoice]);

    const handleSearch = () => {
        setResiParams((prev) => ({
            ...prev,
            page: 1,
            search: searchValue,
        }));
    };

    const handleClearFilter = () => {
        setResiParams(initialParams);
        setDate(null);
        setDateAkhir(null);
        setSearchValue("");
        setStatus([]);
    };

    useEffect(() => {
        if (status.length === 0) {
            setResiParams((prev) => ({
                ...prev,
                page: 1,
                status: "1,2,3,4",
            }));
            return;
        }

        setResiParams((prev) => ({
            ...prev,
            page: 1,
            status: status.map((li) => li.id).join(","),
        }));
    }, [status]);

    useEffect(() => {
        if (!date || !dateAkhir) return;

        setResiParams((prev) => ({
            ...prev,
            page: 1,
            tanggal_awal: date.format("YYYY-MM-DD"),
            tanggal_akhir: dateAkhir.format("YYYY-MM-DD"),
        }));
    }, [date, dateAkhir]);

    const rows: GridRowsProp = resi ?? [];

    const columns: GridColDef[] = [
        {
            field: "nomor",
            headerName: "No",
            headerAlign: "center",
            align: "center",
            maxWidth: 100,
            headerClassName: "header-table",
        },
        {
            field: "no_invoice",
            headerName: "No Invoice",
            minWidth: 150,
            flex: 1,
            headerClassName: "header-table",
        },
        {
            field: "created_at",
            headerName: "Tanggal",
            headerAlign: "center",
            align: "center",
            minWidth: 150,
            headerClassName: "header-table",
            renderCell(params) {
                return dayjs(params.value, "DD-MM-YYYY").format("DD MMM YYYY");
            },
        },
        {
            field: "tgl_due_date",
            headerName: "Due Date",
            headerAlign: "center",
            align: "center",
            minWidth: 150,
            headerClassName: "header-table",
            renderCell(params) {
                if (params.row.status === "Sudah Ditagih" && params.value) {
                    return dayjs(params.value, "DD-MM-YYYY").format("DD MMM YYYY");
                }
                return "-";
            },
        },
        {
            field: "total_tagihan",
            headerName: "Total Tagihan",
            headerAlign: "center",
            align: "center",
            minWidth: 200,
            headerClassName: "header-table",
            renderCell(params) {
                return `Rp ${toRibuan(params.value ?? 0)}`;
            },
        },
        {
            field: "no_resi",
            headerName: "No Resi",
            headerAlign: "center",
            align: "center",
            minWidth: 100,
            maxWidth: 100,
            headerClassName: "header-table",
            renderCell(params) {
                const detail_resi: InvoiceContent["detail_resi"] = params.row.detail_resi;
                return (
                    <span style={{ whiteSpace: "pre-wrap" }}>
                        {detail_resi?.map((li) => li.no_resi).join(`\n`)}
                    </span>
                );
            },
        },
        {
            field: "shipping_mark",
            headerName: "Shipping Mark",
            headerAlign: "center",
            align: "center",
            minWidth: 200,
            maxWidth: 350,
            headerClassName: "header-table",
            renderCell(params) {
                const detail_resi: InvoiceContent["detail_resi"] = params.row.detail_resi;
                return (
                    <span style={{ whiteSpace: "pre-wrap" }}>
                        {detail_resi?.map((li) => li.shipping_mark).join(`\n`)}
                    </span>
                );
            },
        },
        {
            field: "status",
            headerName: "Status Invoice",
            headerAlign: "center",
            align: "center",
            minWidth: 300,
            headerClassName: "header-table",
            renderCell(params) {
                return <Chip label={params?.value} color="primary" variant="outlined" />;
            },
        },
        {
            field: "action_id",
            headerName: "Aksi",
            headerAlign: "center",
            align: "center",
            headerClassName: "header-table",
            maxWidth: 160,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            size="small"
                            color="buttongreen"
                            disableElevation
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                            onClick={() => setSelectedInvoice(params.value)}
                        >
                            <VisibilityIcon sx={{ width: "16px", height: "16px" }} />
                        </Button>
                    </Stack>
                );
            },
        },
    ];

    function truncate(str: string, n: number) {
        return str.length > n ? str.slice(0, n - 1) + `...` : str;
    }

    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: 2,
        maxHeight: 580,
        overflow: "auto",
    };

    return (
        <MainContainer title="Invoice Saya" subtitle="Daftar semua invoice saya">
            <Stack paddingY={3} spacing={3}>
                <Stack direction="row" gap={3}>
                    <OutlinedInput
                        name="search"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.code === "Enter") {
                                handleSearch();
                            }
                        }}
                        sx={{ width: "80%" }}
                        placeholder="Cari Berdasarkan Nomor Invoice"
                        endAdornment={
                            <IconButton onClick={handleSearch}>
                                <SearchIcon color="secondary" />
                            </IconButton>
                        }
                    />
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleClearFilter}
                    >
                        Reset Filter
                    </Button>
                </Stack>
                <Stack direction="row" gap={3}>
                    <Autocomplete
                        disablePortal
                        multiple
                        options={resiStatus ?? []}
                        value={status}
                        onChange={(_, newValue) => {
                            setStatus(newValue);
                        }}
                        getOptionLabel={(option: DefaultOption) => option.nama}
                        sx={{ width: "45%" }}
                        renderInput={(params) => (
                            <TextField {...params} label="Pilih Status Invoice" />
                        )}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={truncate(option.nama, 10)}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        limitTags={3}
                    />
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={3}
                        sx={{ width: "50%" }}
                    >
                        <DatePicker
                            sx={{ width: "48%" }}
                            label="Pilih Tanggal Awal"
                            value={date}
                            onChange={(value) => setDate(value)}
                        />
                        <RemoveIcon />
                        <DatePicker
                            sx={{ width: "48%" }}
                            label="Pilih Tanggal Akhir"
                            value={dateAkhir}
                            onChange={(value) => setDateAkhir(value)}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <Box>
                <DataGrid
                    autoHeight
                    getRowHeight={() => "auto"}
                    sx={{
                        "& .MuiDataGrid-cell": {
                            padding: "1em",
                            alignItems: "flex-start !important",
                        },
                    }}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                page: initialParams.page,
                                pageSize: initialParams.size,
                            },
                        },
                    }}
                    checkboxSelection={false}
                    getRowId={(row) => row.id}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    paginationMode="server"
                    rowCount={rowCount}
                    loading={resiData.isLoading}
                    pageSizeOptions={[10]}
                    paginationModel={{
                        pageSize: resiParams.size,
                        page: resiParams.page - 1,
                    }}
                    onPaginationModelChange={({ page, pageSize }) => {
                        setResiParams((prev) => ({
                            ...prev,
                            page: page + 1,
                            size: pageSize,
                        }));
                    }}
                    density="comfortable"
                />
            </Box>
            <Modal open={!!selectedInvoice} onClose={() => setSelectedInvoice(undefined)}>
                <Box p={4} sx={style}>
                    <>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Stack>
                                <Typography variant="h5" fontWeight="bold">
                                    {selectedData?.no_invoice ?? "-"}
                                </Typography>
                                <Typography variant="body2">
                                    {dayjs(selectedData?.created_at, "DD-MM-YYYY").format(
                                        "DD MMM YYYY"
                                    ) ?? "-"}
                                </Typography>
                            </Stack>
                            <IconButton onClick={() => setSelectedInvoice(undefined)}>
                                <Close />
                            </IconButton>
                        </Stack>
                        <Box my={2}>
                            <Chip
                                label={selectedData?.status}
                                color="primary"
                                variant="outlined"
                            />
                        </Box>
                        <Stack spacing={1} mt={4}>
                            {selectedData?.status === "Lunas" ? (
                                <InfoDescription
                                    title="Tanggal Lunas"
                                    subtitle={
                                        selectedData?.tgl_lunas
                                            ? dayjs(
                                                  selectedData?.tgl_lunas,
                                                  "DD-MM-YYYY"
                                              ).format("DD MMM YYYY")
                                            : "-"
                                    }
                                />
                            ) : selectedData?.status === "Tempo" ? (
                                <InfoDescription
                                    title="Tanggal Tempo"
                                    subtitle={
                                        dayjs(
                                            selectedData?.tgl_tempo,
                                            "DD-MM-YYYY"
                                        ).format("DD MMM YYYY") ?? "-"
                                    }
                                />
                            ) : selectedData?.status === "Sudah Ditagih" ? (
                                <InfoDescription
                                    title="Tanggal Tagih"
                                    subtitle={
                                        dayjs(
                                            selectedData?.tgl_tagih,
                                            "DD-MM-YYYY"
                                        ).format("DD MMM YYYY") ?? "-"
                                    }
                                />
                            ) : null}
                            <InfoDescription
                                title="Total Sudah Bayar (Rp)"
                                subtitle={toRibuan(selectedData?.total_bayar ?? 0)}
                            />
                            <InfoDescription
                                title="Total Tagihan (Rp)"
                                subtitle={toRibuan(selectedData?.total_tagihan ?? 0)}
                            />
                            <InfoDescription
                                title="Rekening Tujuan"
                                subtitle={`${selectedData?.bank} - ${selectedData?.no_rek} a.n. ${selectedData?.nama_rek}`}
                            />
                            <InfoDescription
                                title="Term"
                                subtitle={selectedData?.term ?? "-"}
                            />
                            <InfoDescription
                                title="Keterangan"
                                subtitle={selectedData?.keterangan ?? "-"}
                            />
                        </Stack>
                    </>
                </Box>
            </Modal>
        </MainContainer>
    );
};

type InfoDescription = {
    title: string;
    subtitle: string;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
const InfoDescription = ({ title, subtitle }: InfoDescription) => {
    return (
        <Stack>
            <Typography variant="body2">{title}</Typography>
            <Typography variant="h6" fontWeight="bold">
                {subtitle}
            </Typography>
        </Stack>
    );
};
export default InvoicePage;

import { Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Gift, Lock, TicketPercent, TruckDelivery } from "mdi-material-ui";
import React from "react";

type RewardMembershipProps = {
  type?: string;
  title: string;
  onClickDetail: () => void;
};

export default function RewardMembership({
  type,
  title,
  onClickDetail,
}: RewardMembershipProps) {
  return (
    <Stack
      paddingX={3}
      paddingY={2}
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{
        borderRadius: "4px",
        border: "1px solid #C5C5C5",
        backgroundColor: "#ffffff",
        minWidth: "283px",
      }}
    >
      {type === "Ongkir" ? (
        <TruckDelivery
          htmlColor="#288F17"
          sx={{ width: "36px", height: "36px" }}
        />
      ) : type === "Souvenir" ? (
        <Gift htmlColor="#A900B8" sx={{ width: "36px", height: "36px" }} />
      ) : type === "Potongan diskon perhitungan Persen (%)" ? (
        <TicketPercent
          htmlColor="#CA6100"
          sx={{ width: "36px", height: "36px" }}
        />
      ) : type === "Potongan diskon perhitungan Nominal (Rp)" ? (
        <TicketPercent
          htmlColor="#CA6100"
          sx={{ width: "36px", height: "36px" }}
        />
      ) : (
        <Lock htmlColor="#9E9E9E" sx={{ width: "36px", height: "36px" }} />
      )}
      <Stack spacing={1}>
        <Typography variant="body1">{title}</Typography>
        <Box component="a" href="#" onClick={onClickDetail}>
          <Typography
            variant="body2"
            fontWeight="500"
            color="#1D69C7"
            sx={{ textDecoration: "underline !important" }}
          >
            Lihat detail
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
}

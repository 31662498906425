import { useQuery } from "react-query";
import axios from "../../services/axios";
import { ResiParams, ResiResponse } from "../../types";
import qs from "query-string";

const useGetResi = (params: ResiParams) => {
    const endpoint = qs.stringifyUrl({
        url: "/api/getresi",
        query: params,
    });
    return useQuery(["getresi", { ...params }], () =>
        axios.get<ResiResponse>(endpoint).then((res) => res.data.data)
    );
};

export default useGetResi;

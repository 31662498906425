import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PageLayout from "../layouts/PageLayout";
import LoginPage from "../pages/LoginPage/LoginPage";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import ThemeComponent from "../components/ThemeComponent/ThemeComponent";
import Halaman404 from "../pages/Halaman404/Halaman404";
import ResiPage from "../pages/ResiPage/ResiPage";
import PesananPage from "../pages/PesananPage/PesananPage";
import JasaTransferPage from "../pages/JasaTransferPage/JasaTransferPage";
import PointRoyalty from "../pages/PointRoyalty/PointRoyalty";
import InvoicePage from "../pages/InvoicePage/InvoicePage";

export default function Routes() {
  return (
    <Router>
      <React.StrictMode>
        <Switch>
          <PrivateRoute path="/resi" exact>
            <ThemeComponent>
              <PageLayout>
                <ResiPage />
              </PageLayout>
            </ThemeComponent>
          </PrivateRoute>
          <PrivateRoute path="/invoice" exact>
            <ThemeComponent>
              <PageLayout>
                <InvoicePage />
              </PageLayout>
            </ThemeComponent>
          </PrivateRoute>
          <PrivateRoute path="/pesanan" exact>
            <ThemeComponent>
              <PageLayout>
                <PesananPage />
              </PageLayout>
            </ThemeComponent>
          </PrivateRoute>
          <PrivateRoute path="/jasa-transfer" exact>
            <ThemeComponent>
              <PageLayout>
                <JasaTransferPage />
              </PageLayout>
            </ThemeComponent>
          </PrivateRoute>
          <PrivateRoute path="/point-royalty" exact>
            <ThemeComponent>
              <PageLayout>
                <PointRoyalty />
              </PageLayout>
            </ThemeComponent>
          </PrivateRoute>
          <PublicRoute path="/" exact>
            <LoginPage />
          </PublicRoute>
          <Route path="*">
            <Halaman404 />
          </Route>
        </Switch>
      </React.StrictMode>
    </Router>
  );
}

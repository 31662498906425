import { useQuery } from 'react-query';
import axios from '../../services/axios';
import { ResiParams, PesananResponse } from '../../types';
import qs from 'query-string';

const useGetPesanan = (params: ResiParams) => {
    const endpoint = qs.stringifyUrl({
        url: '/api/my-order',
        query: params,
    });
    return useQuery(['order', { ...params }], () =>
        axios.get<PesananResponse>(endpoint).then((res) => res.data.data)
    );
};

export default useGetPesanan;

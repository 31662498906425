import { useEffect, useState } from "react";
import { DefaultOption, ResiParams } from "../../types";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import MainContainer from "../../components/MainContainer/MainContainer";
import {
  Stack,
  Button,
  Box,
  OutlinedInput,
  IconButton,
  Autocomplete,
  TextField,
  Chip,
  Modal,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RemoveIcon from "@mui/icons-material/Remove";
import { Close } from "@mui/icons-material";
import useGetJasaTransfer from "../../services/queries/useGetJasaTransfer";
import useGetStatusJasaTransfer from "../../services/queries/useGetStatusJasaTransfer";
import toRibuan from "../../utils/toRibuan";
import { JasaTransferResponse } from "../../constants/Types";

const JasaTransferPage = () => {
  const initialParams: ResiParams = {
    size: 10,
    page: 1,
  };

  const [resiParams, setResiParams] = useState<ResiParams>(initialParams);
  const [searchValue, setSearchValue] = useState("");
  const [status, setStatus] = useState<DefaultOption[]>([]);
  const [date, setDate] = useState<Dayjs | null>(null);
  const [dateAkhir, setDateAkhir] = useState<Dayjs | null>(null);
  // const [selectedResi, setSelectedResi] = useState<string | undefined>(undefined);
  const [selectedData, setSelectedData] = useState<
    JasaTransferResponse["content"][0] | null
  >(null);

  const resiData = useGetJasaTransfer(resiParams);
  const resi = resiData.data?.content.map((li, idx) => ({
    nomor: idx + 1,
    all_nominal: [li.nominal_rp, li.nominal_rmb, li.nominal_dollar],
    ...li,
  }));
  const rowCount = resiData.data?.total_elements ?? 0;

  const resiStatusData = useGetStatusJasaTransfer();
  const resiStatus = resiStatusData.data;

  // const detailResiData = useGetDetailResi(selectedResi);
  // const detailResi = detailResiData.data?.data;

  const handleSearch = () => {
    setResiParams((prev) => ({
      ...prev,
      page: 1,
      search: searchValue,
    }));
  };

  const handleClearFilter = () => {
    setResiParams(initialParams);
    setDate(null);
    setDateAkhir(null);
    setSearchValue("");
    setStatus([]);
  };

  useEffect(() => {
    if (status.length === 0) {
      setResiParams((prev) => ({
        ...prev,
        page: 1,
        status: undefined,
      }));
      return;
    }

    setResiParams((prev) => ({
      ...prev,
      page: 1,
      status: status.map((li) => li.id).join(","),
    }));
  }, [status]);

  useEffect(() => {
    if (!date || !dateAkhir) return;

    setResiParams((prev) => ({
      ...prev,
      page: 1,
      tanggal_awal: date.format("YYYY-MM-DD"),
      tanggal_akhir: dateAkhir.format("YYYY-MM-DD"),
    }));
  }, [date, dateAkhir]);

  const rows: GridRowsProp = resi ?? [];

  const columns: GridColDef[] = [
    {
      field: "nomor",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      maxWidth: 100,
      headerClassName: "header-table",
    },
    {
      field: "no_order",
      headerName: "No Order",
      minWidth: 200,
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "tanggal_transfer",
      headerName: "Tanggal",
      minWidth: 150,
      flex: 1,
      headerClassName: "header-table",
      renderCell(params) {
        return params.value ? params.value.split(",")[0] : params.value;
      },
    },
    {
      field: "total_rp",
      headerName: "Total (Rp)",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      headerClassName: "header-table",
      renderCell(params) {
        return `Rp ${toRibuan(params.value ?? 0)}`;
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      minWidth: 200,
      headerClassName: "header-table",
      renderCell(params) {
        return (
          <Chip label={params?.value} color="primary" variant="outlined" />
        );
      },
    },
    {
      field: "all_nominal",
      headerName: "Nominal",
      headerAlign: "center",
      align: "center",
      minWidth: 200,
      headerClassName: "header-table",
      renderCell(params) {
        return (
          <Box sx={{ paddingY: 2 }}>
            <div>Rp {toRibuan(params.value[0] ?? 0)}</div>
            <div>¥ {toRibuan(params.value[1] ?? 0)}</div>
            <div>$ {toRibuan(params.value[2] ?? 0)}</div>
          </Box>
        );
      },
    },
    // {
    //     field: "last_update",
    //     headerName: "Terakhir diperbarui",
    //     headerAlign: "center",
    //     align: "center",
    //     minWidth: 200,
    //     flex: 1,
    //     headerClassName: "header-table",
    // },
    {
      field: "id",
      headerName: "Aksi",
      headerAlign: "center",
      align: "center",
      headerClassName: "header-table",
      maxWidth: 160,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              size="small"
              color="buttongreen"
              disableElevation
              sx={{
                minWidth: "unset",
                padding: "8px",
                width: "32px",
                height: "32px",
              }}
              onClick={() => {
                const selected = resi?.find((li) => li.id === params.value);
                if (selected) {
                  setSelectedData(selected);
                }
              }}
            >
              <VisibilityIcon sx={{ width: "16px", height: "16px" }} />
            </Button>
            {/* <Button
                            variant="contained"
                            size="small"
                            color="buttonyellow"
                            disableElevation
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                            onClick={() => handleDownload(params.value)}
                        >
                            <VisibilityIcon sx={{ width: "16px", height: "16px" }} />
                        </Button> */}
          </Stack>
        );
      },
    },
  ];

  function truncate(str: string, n: number) {
    return str.length > n ? str.slice(0, n - 1) + `...` : str;
  }

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  // if (resiData.isLoading) {
  //     return (
  //         <Stack height="80vh" alignItems="center" justifyContent="center">
  //             <CircularProgress />
  //         </Stack>
  //     );
  // }

  return (
    <MainContainer
      title="Jasa Transfer"
      subtitle="Semua daftar transaksi jasa transfer"
    >
      <Stack paddingY={3} spacing={3}>
        <Stack direction="row" gap={3}>
          <OutlinedInput
            name="search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                handleSearch();
              }
            }}
            sx={{ width: "80%" }}
            placeholder="Cari Berdasarkan Nomor Order"
            endAdornment={
              <IconButton onClick={handleSearch}>
                <SearchIcon color="secondary" />
              </IconButton>
            }
          />
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClearFilter}
          >
            Reset Filter
          </Button>
        </Stack>
        <Stack direction="row" gap={3}>
          <Autocomplete
            disablePortal
            multiple
            options={resiStatus ?? []}
            value={status}
            onChange={(_, newValue) => {
              setStatus(newValue);
            }}
            getOptionLabel={(option: DefaultOption) => option.nama}
            sx={{ width: "45%" }}
            renderInput={(params) => (
              <TextField {...params} label="Pilih Status Transfer" />
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={truncate(option.nama, 10)}
                  {...getTagProps({ index })}
                />
              ))
            }
            limitTags={3}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={3}
            sx={{ width: "50%" }}
          >
            <DatePicker
              sx={{ width: "48%" }}
              label="Pilih Tanggal Awal"
              value={date}
              onChange={(value) => setDate(value)}
            />
            <RemoveIcon />
            <DatePicker
              sx={{ width: "48%" }}
              label="Pilih Tanggal Akhir"
              value={dateAkhir}
              onChange={(value) => setDateAkhir(value)}
            />
          </Stack>
        </Stack>
      </Stack>
      <Box>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                page: initialParams.page,
                pageSize: initialParams.size,
              },
            },
          }}
          checkboxSelection={false}
          getRowId={(row) => row.nomor}
          disableColumnMenu
          disableRowSelectionOnClick
          paginationMode="server"
          rowCount={rowCount}
          loading={resiData.isLoading}
          pageSizeOptions={[10]}
          paginationModel={{
            pageSize: resiParams.size,
            page: resiParams.page - 1,
          }}
          onPaginationModelChange={({ page, pageSize }) => {
            setResiParams((prev) => ({
              ...prev,
              page: page + 1,
              size: pageSize,
            }));
          }}
          density="comfortable"
        />
      </Box>
      <Modal open={!!selectedData} onClose={() => setSelectedData(null)}>
        <Box sx={style}>
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack>
                <Typography variant="h5" fontWeight="bold">
                  {selectedData?.no_order}
                </Typography>
                <Typography variant="body2">
                  Tanggal transfer: {selectedData?.tanggal_transfer}
                </Typography>
              </Stack>
              <IconButton onClick={() => setSelectedData(null)}>
                <Close />
              </IconButton>
            </Stack>
            <Box my={2}>
              <Chip
                label={selectedData?.status}
                color="primary"
                variant="outlined"
              />
            </Box>
            <Stack spacing={1} mt={4}>
              <InfoDescription
                title="Total (Rp)"
                subtitle={toRibuan(selectedData?.total_rp ?? 0)}
              />
              <Stack>
                <Typography variant="body2">Nominal</Typography>
                <Typography variant="h6" fontWeight="bold">
                  Rp {toRibuan(selectedData?.nominal_rp ?? 0)}
                </Typography>
                <Typography variant="h6" fontWeight="bold">
                  ¥ {toRibuan(selectedData?.nominal_rmb ?? 0)}
                </Typography>
                <Typography variant="h6" fontWeight="bold">
                  $ {toRibuan(selectedData?.nominal_dollar ?? 0)}
                </Typography>
              </Stack>
              <InfoDescription
                title="Rekening Tujuan CN"
                subtitle={selectedData?.rekening_tujuan_cn ?? "-"}
              />
              <InfoDescription
                title="Keterangan"
                subtitle={selectedData?.keterangan ?? "-"}
              />
            </Stack>
          </>
        </Box>
      </Modal>
    </MainContainer>
  );
};

type InfoDescription = {
  title: string;
  subtitle: string;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
const InfoDescription = ({ title, subtitle }: InfoDescription) => {
  return (
    <Stack>
      <Typography variant="body2">{title}</Typography>
      <Typography variant="h6" fontWeight="bold">
        {subtitle}
      </Typography>
    </Stack>
  );
};

export default JasaTransferPage;

import { useQuery } from "react-query";
import { StatusJasaTransferResponse } from "../../constants/Types";
import axios from "../../services/axios";

type GetStatusJasaTransfer = {
    code: number;
    message: string;
    data: StatusJasaTransferResponse[];
};

const useGetStatusJasaTransfer = () => {
    return useQuery(
        "statusJasaTransfer",
        () =>
            axios
                .get<GetStatusJasaTransfer>("/api/getstatusjasatransfer")
                .then((res) => res.data.data),
        {
            refetchOnWindowFocus: false,
        }
    );
};

export default useGetStatusJasaTransfer;

import { useQuery } from "react-query";
import { coloadResponse } from "../../constants/Types";
import axios from "../../services/axios";

export type DetailVoucherResponse = {
    code: number;
    message: string;
    data: {
        id: number;
        title: string;
        deskripsi: string;
        kadaluwarsa: string;
        syarat: {
            id: number;
            val: string;
        }[];
    };
};

const useDetailVoucher = ({ id }: { id: number }) => {
    return useQuery(["detail-voucher", id], async () => {
        const { data } = await axios.get<DetailVoucherResponse>(
            `/api/getdetailvoucher/${id}`
        );
        return data.data;
    });
};

export default useDetailVoucher;

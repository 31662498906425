import React from "react";
import { useAuth } from "../../contexts/authContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import shadows, { Shadows } from "@mui/material/styles/shadows";
import { idID } from "@mui/material/locale";
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from "@mui/material";
import Spinner from "../Spinner/Spinner";
import { defaultColor, defaultSecondaryColor, font } from "../../constants/common";

interface IThemeComponentProps {
    children: React.ReactNode;
}

declare module "@mui/material/styles" {
    interface Palette {
        abu: Palette["primary"];
        ulo: Palette["primary"];
        whatsapp: Palette["primary"];
        bolddanger: Palette["primary"];
        greyc4: Palette["primary"];
        lightblue: Palette["primary"];
        buttonblue: Palette["primary"];
        buttonyellow: Palette["primary"];
        buttonred: Palette["primary"];
        buttongreen: Palette["primary"];
        buttonulo: Palette["primary"];
        lightwarning: Palette["primary"];
        lighterror: Palette["primary"];
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        abu?: PaletteOptions["primary"];
        ulo?: PaletteOptions["primary"];
        whatsapp?: PaletteOptions["primary"];
        bolddanger?: PaletteOptions["primary"];
        greyc4?: PaletteOptions["primary"];
        lightblue?: PaletteOptions["primary"];
        buttonblue?: PaletteOptions["primary"];
        buttonyellow?: PaletteOptions["primary"];
        buttonred?: PaletteOptions["primary"];
        buttongreen?: PaletteOptions["primary"];
        buttonulo?: PaletteOptions["primary"];
        lightwarning?: PaletteOptions["primary"];
        lighterror?: PaletteOptions["primary"];
    }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        abu: true;
        ulo: true;
        whatsapp: true;
        bolddanger: true;
        greyc4: true;
        lightblue: true;
        buttonblue: true;
        buttonyellow: true;
        buttonred: true;
        buttongreen: true;
        buttonulo: true;
        lightwarning: true;
        lighterror: true;
    }
}

const ThemeComponent = ({ children }: IThemeComponentProps) => {
    const theme = createTheme(
        {
            palette: {
                primary: {
                    main: defaultColor,
                    // light: "#6AB893",
                    // dark: "#307454",
                    contrastText: "#fff",
                },
                secondary: {
                    main: defaultSecondaryColor,
                },
                // background: {
                //     default: "#F5F5F4",
                // },
                abu: {
                    main: "#fff",
                },
                ulo: {
                    main: defaultColor,
                    contrastText: "#fff",
                },
                whatsapp: {
                    main: "#399F3A",
                    contrastText: "#fff",
                },
                bolddanger: {
                    main: "#E52828",
                },
                greyc4: {
                    main: "#C4C4C4",
                    contrastText: "#fff",
                },
                lightblue: {
                    main: "#1976D2",
                },
                buttonblue: {
                    main: "#2F9DEC",
                    contrastText: "#fff",
                },
                buttonyellow: {
                    main: "#FFC46C",
                    contrastText: "#fff",
                },
                buttonred: {
                    main: "#FF7373",
                    contrastText: "#fff",
                },
                buttongreen: {
                    main: "#13AE10",
                    contrastText: "#fff",
                },
                buttonulo: {
                    main: "#45a779 ",
                    contrastText: "#fff",
                },
                lightwarning: {
                    main: "#ED6C02",
                    contrastText: "#fff",
                },
                lighterror: {
                    main: "#D32F2F",
                    contrastText: "#fff",
                },
            },
            typography: {
                fontFamily: font,
                fontWeightMedium: 500,
                fontWeightBold: 700,
            },
            // shadows: shadows.map(() => "none") as Shadows,
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            boxShadow: shadows.map(() => "none") as Shadows,
                        },
                    },
                },
            },
        },
        idID
    );

    // if (statusUkm === "loading") {
    //     return (
    //         <Box position="relative" height="100vh">
    //             <Spinner />
    //         </Box>
    //     );
    // }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default ThemeComponent;

import { useQuery } from 'react-query';
import { DetailResiNewFormat } from '../../constants/Types';
import axios from '../../services/axios';

type GetDetailResi = {
    code: number;
    message: string;
    data: DetailResiNewFormat;
};

const useGetDetailResi = (no_resi?: string) => {
    return useQuery<GetDetailResi | null>(['getdetailresi', no_resi], () => {
        if (!no_resi) return null;

        return axios
            .get(`/api/getdetailresi/${no_resi}`)
            .then((res) => res.data);
    });
};

export default useGetDetailResi;

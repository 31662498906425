import {
  Box,
  Button,
  Typography,
  FormControl,
  FormHelperText,
  OutlinedInput,
  useMediaQuery,
  Link,
  IconButton,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import cover from "./covers.jpg";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth } from "../../contexts/authContext";
// import { ReactComponent as LoginImage } from "../../assets/images/LoginImage.svg";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import ThemeDefaultComponent from "../../components/ThemeDefaultComponent/ThemeDefaultComponent";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import { defaultColor } from "../../constants/common";

interface ILoginInputs {
  email: string;
  password: string;
}

const Schema = yup
  .object({
    email: yup
      .string()
      // .email("Format email salah")
      .required("Kolom wajib diisi"),
    password: yup.string().required("Kolom wajib diisi"),
  })
  .required();

const LoginPage = () => {
  const theme = useTheme();
  const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLaptopScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const { login, isLoading } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [saveEmail, setSaveEmail] = useState(false);
  const [initEmail, setInitEmail] = useState<any>();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const queryEmail = urlParams.get("email");

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const initialValues = useMemo(
    () => ({
      email: initEmail ?? "",
      password: "",
    }),
    [initEmail]
  );

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<ILoginInputs>({
    resolver: yupResolver(Schema),
    defaultValues: initialValues,
  });

  const watchEmail = watch("email");

  const handleSaveEmail = (checked: boolean) => {
    if (checked) {
      setSaveEmail(true);
    } else {
      setSaveEmail(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("email")) {
      setSaveEmail(true);
    } else {
      setSaveEmail(false);
    }
  }, []);

  useEffect(() => {
    if (queryEmail) {
      reset({ email: queryEmail ?? "", password: "" });
    } else if (localStorage.getItem("email")) {
      reset({ email: localStorage.getItem("email") ?? "", password: "" });
    } else {
      setInitEmail("");
    }
  }, [queryEmail, reset]);

  const onSubmit = (data: ILoginInputs) => {
    login(data);
    if (saveEmail) {
      // localStorage.setItem("saveEmail", "true");
      localStorage.setItem("email", watchEmail);
    } else {
      // localStorage.setItem("saveEmail", "false");
      localStorage.removeItem("email");
    }
  };

  return (
    <ThemeDefaultComponent>
      <Box
        padding={3}
        display={isDesktopScreen ? undefined : "flex"}
        justifyContent={isDesktopScreen ? undefined : "center"}
        sx={{ backgroundColor: "white" }}
      >
        <Box
          sx={{
            minHeight: "100vh",
            minWidth: "100vw",
            width: isTabletScreen ? undefined : "fit-content",
            // width:
            //     isPhoneScreen || isTabletScreen
            //         ? undefined
            //         : isLaptopScreen
            //         ? "90%"
            //         : "650px",
            // height: isPhoneScreen
            //     ? undefined
            //     : isLaptopScreen
            //     ? "90%"
            //     : "550px",
            position: "absolute",
            top: "50%",
            left: isTabletScreen ? "50%" : "25%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#FFF",
            display: "flex",
            justifyContent: "center",
            borderRadius: 1,
            paddingX: 2,
            paddingY: 2,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            paddingY={3}
            paddingX={isTabletScreen ? 2 : 4}
            sx={{
              backgroundColor: "#fff",
              borderRadius: "inherit",
            }}
          >
            <form
              style={{
                width: "100%",
                maxWidth: "500px",
                minWidth: "200px",
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "24px",
                }}
              >
                <img
                  src="/images/antara-logo.png"
                  width="150px"
                  height="50px"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "fit-content",
                  marginTop: 3,
                  marginBottom: 6,
                }}
              >
                <Typography variant="h4" fontWeight="bold" color="#000">
                  Masuk
                </Typography>
                <Typography variant="body2" color="#a6a6a6">
                  Pantau resi Anda melalui Antara Web App
                </Typography>
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body2">Email atau Username</Typography>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={Boolean(errors.email)}
                        sx={{
                          maxWidth: "500px",
                        }}
                      >
                        <OutlinedInput
                          id="email"
                          // type="email"
                          autoComplete="email"
                          autoFocus={isLaptopScreen}
                          placeholder="Contoh: budi1992@gmail.com / budi1992"
                          size="small"
                          {...field}
                        />
                        <FormHelperText>
                          {errors.email ? errors.email.message : " "}
                        </FormHelperText>
                      </FormControl>
                    )}
                    rules={{ required: "Email required" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">Kata Sandi</Typography>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={Boolean(errors.password)}
                        sx={{
                          maxWidth: "500px",
                        }}
                      >
                        <OutlinedInput
                          id="password"
                          type={showPassword ? "text" : "password"}
                          autoComplete="password"
                          placeholder="Minimal 8 karakter"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          size="small"
                          {...field}
                        />
                        <FormHelperText>
                          {errors.password ? errors.password.message : " "}
                        </FormHelperText>
                      </FormControl>
                    )}
                    rules={{
                      required: "Kata sandi required",
                    }}
                  />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end">
                {/* <Link
                                    href={`https://api.whatsapp.com/send?phone=${noWaAdmin?.nomor}&text=Saya%20lupa%20kata%20sandi%20akun%20UKM%20LOKAL`}
                                    variant="body2"
                                    underline="hover"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Lupa Kata Sandi?
                                </Link> */}
                <Link
                  component={RouterLink}
                  to="/lupa-kata-sandi"
                  variant="body2"
                  underline="hover"
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  Lupa Kata Sandi?
                </Link>
              </Box>
              {/* <Box
                                width="100%"
                                display="flex"
                                justifyContent="flex-between"
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={saveEmail}
                                            onChange={(_, checked) => {
                                                handleSaveEmail(checked);
                                            }}
                                        />
                                    }
                                    label="Simpan Email ?"
                                    sx={{
                                        height: "fit-content",
                                    }}
                                />
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        resetLoginCount();
                                    }}
                                    sx={{ display: "none" }}
                                >
                                    Reset timer
                                </Button>
                            </Box> */}
              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                color="secondary"
                sx={{ marginTop: 3, maxWidth: "500px" }}
              >
                Masuk
              </Button>
              {/* <Button
                                fullWidth
                                size="large"
                                variant="outlined"
                                href={
                                    process.env.NODE_ENV === "production"
                                        ? `https://juragan.ukmlokal.com`
                                        : `http://localhost:3000`
                                }
                                rel="noreferrer"
                                sx={{ marginTop: 2, maxWidth: "500px" }}
                            >
                                Ke Juragan
                            </Button> */}
              {/* <Typography
                                variant="body2"
                                component="div"
                                textAlign="center"
                                sx={{ marginY: 2 }}
                            >
                                Belum punya akun?{" "}
                                <Link
                                    component={RouterLink}
                                    to="/registrasi"
                                    variant="body2"
                                    underline="hover"
                                    sx={{
                                        cursor: "pointer",
                                    }}
                                >
                                    Daftar
                                </Link>
                            </Typography> */}
            </form>
          </Box>
        </Box>
        <LoadingOverlay open={isLoading} />
      </Box>
      {isTabletScreen === false ? (
        <Box
          sx={{
            minHeight: "100vh",
            minWidth: "50vw",
            position: "absolute",
            top: "49.9%",
            left: "75%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            display: "flex",
            backgroundImage: `url(${cover})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top right",
            backgroundSize: "cover",
          }}
        />
      ) : null}
    </ThemeDefaultComponent>
  );
};

export default LoginPage;

import React, { useEffect, useMemo, useState } from "react";
import MainContainer from "../../components/MainContainer/MainContainer";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Modal,
  Stack,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import { BuildCircleOutlined } from "@mui/icons-material";
import BadgeMember from "../../components/BadgeMember/BadgeMember";
import toRibuan from "../../utils/toRibuan";
import MiniBadgeMember from "../../components/MiniBadgeMember/MiniBadgeMember";
import useLoyalty from "../../services/queries/useLoyalty";
import dayjs from "dayjs";
import RewardMembership from "../../components/RewardMembership/RewardMembership";
import useReward from "../../services/queries/useReward";
import useVoucher from "../../services/queries/useVoucher";
import VoucherCard from "../../components/VoucherCard/VoucherCard";
import useDetailVoucher from "../../services/queries/useDetailVoucher";
import TimerOutline from "mdi-material-ui/TimerOutline";
import { Close } from "mdi-material-ui";

interface IPointRoyaltyProps {}

const PointRoyalty = (props: IPointRoyaltyProps) => {
  const [voucherId, setVoucherId] = useState<number | null>(null);

  const loyaltyQuery = useLoyalty();
  const loyalty = loyaltyQuery.data;

  const rewardQuery = useReward();
  const rewards = rewardQuery.data;
  const nama_ekspedisi = "";
  const voucherQuery = useVoucher({ nama_ekspedisi });
  const vouchers = voucherQuery.data;

  const renderStatusColor = (status?: string) => {
    switch (status) {
      case "Bronze":
        return "#C8AF7E";

      case "Silver":
        return "#D8DADB";

      case "Gold":
        return "#ECCB64";

      case "Platinum":
        return "#628CA6";

      default:
        return "#C8AF7E";
    }
  };

  const renderStatusBarColor = (status?: string) => {
    switch (status) {
      case "Bronze":
        return "linear-gradient(90deg, #C8AF7E 0%, #7D6433 100%)";

      case "Silver":
        return "linear-gradient(90deg, #D8DADB 0%, #868686 100.33%)";

      case "Gold":
        return "linear-gradient(90deg, #ECCB64 0%, #B59638 100.33%)";

      case "Platinum":
        return "linear-gradient(90deg, #628CA6 0%, #335870 100.33%)";

      default:
        return "linear-gradient(90deg, #C8AF7E 0%, #7D6433 100%)";
    }
  };

  const openModalWithId = (id: number) => {
    setVoucherId(id);
  };

  const BorderLinearProgress = useMemo(
    () =>
      styled(LinearProgress)(({ theme }) => ({
        height: 12,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor:
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 5,
          background: renderStatusBarColor(loyalty?.status),
        },
      })),
    [loyalty?.status]
  );

  const transactionLeft = useMemo(
    () => Number(loyalty?.nextTransaksi ?? 0),
    [loyalty]
  );

  const progressMember = useMemo(() => {
    const result =
      (Number(loyalty?.totalTransaksi ?? 0) /
        (Number(loyalty?.nextTransaksi ?? 0) +
          Number(loyalty?.totalTransaksi ?? 0))) *
      100;

    return Math.ceil(result) > 100 ? 100 : Math.ceil(result);
  }, [loyalty]);

  const listVoucher = useMemo(
    () => vouchers?.content?.filter((li) => !li.status_voucher) ?? [],
    [vouchers?.content]
  );

  return (
    <>
      <MainContainer
        title="Point Royalty"
        subtitle="Gunakan poin Anda untuk dapatkan promo!"
      >
        <Stack spacing={6}>
          <Stack
            sx={{
              borderRadius: "20px",
              border: "1px solid #141414",
              background:
                "linear-gradient(211deg, rgba(66, 66, 66, 0.16) 0%, rgba(34, 34, 34, 0.16) 37.49%, rgba(66, 66, 66, 0.16) 37.5%, rgba(34, 34, 34, 0.16) 66.15%, rgba(66, 66, 66, 0.16) 66.16%, rgba(34, 34, 34, 0.16) 100%), #222",
              backgroundBlendMode: "screen, normal",
              boxShadow: "0px 2px 10px 10px rgba(0, 0, 0, 0.05)",
            }}
            paddingX={4}
            paddingY={3}
            spacing={6}
          >
            {loyaltyQuery.isLoading ? (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                height="150px"
              >
                <CircularProgress size={48} sx={{ color: "white" }} />
              </Stack>
            ) : (
              <>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <BadgeMember fill={renderStatusColor(loyalty?.status)} />
                  <Typography variant="h5" fontWeight="700" color="#ffffff">
                    {loyalty?.status === "-" ? "Platinum" : loyalty?.status}{" "}
                    Member
                  </Typography>
                </Stack>
                <Stack spacing={2}>
                  <Stack spacing={1}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="caption" color="#ffffff">
                        Transaksi lagi senilai Rp {toRibuan(transactionLeft)}
                      </Typography>
                      <Typography variant="caption" color="#ffffff">
                        {loyalty?.nextStatus === "-"
                          ? "Platinum"
                          : loyalty?.nextStatus}
                      </Typography>
                    </Stack>
                    <Box position="relative">
                      <BorderLinearProgress
                        variant="determinate"
                        value={progressMember}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          right: -6,
                          top: -7,
                        }}
                      >
                        <MiniBadgeMember
                          fill={renderStatusColor(
                            loyalty?.nextStatus === "-"
                              ? "Platinum"
                              : loyalty?.nextStatus
                          )}
                        />
                      </Box>
                    </Box>
                  </Stack>
                  <Typography variant="body2" color="#ffffff">
                    Jadi{" "}
                    {loyalty?.nextStatus === "-"
                      ? "Platinum"
                      : loyalty?.nextStatus}{" "}
                    Member dengan selesaikan progres di atas sebelum{" "}
                    {dayjs(loyalty?.tanggalReset, "DD-MM-YYYY").format(
                      "DD MMMM YYYY"
                    )}
                    !
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
          <Stack spacing={2}>
            <Typography variant="body1" fontWeight="500">
              Reward Membership
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              spacing={4}
              sx={{
                maxWidth: "calc(100% - 12px)",
                overflowX: "scroll",
              }}
              className="hide-scroll"
            >
              {rewardQuery.isLoading ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  height="100px"
                >
                  <CircularProgress size={48} color="primary" />
                </Stack>
              ) : (
                <>
                  {rewards?.map((li, idx) => (
                    <RewardMembership
                      key={idx.toString()}
                      type={li.type}
                      title={li.title}
                      onClickDetail={() => setVoucherId(li.id)}
                    />
                  ))}
                </>
              )}
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="body1" fontWeight="500">
              Voucher Saya
            </Typography>
            <Box sx={{ width: "100%" }}>
              {voucherQuery.isLoading ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  height="100px"
                >
                  <CircularProgress size={48} color="primary" />
                </Stack>
              ) : (
                <Grid container spacing={2}>
                  {listVoucher.length === 0 && (
                    <Grid item xs={12}>
                      <Typography variant="body2" color="#C5C5C5">
                        Tidak ada voucher tersedia
                      </Typography>
                    </Grid>
                  )}
                  {listVoucher.map((li, idx) => (
                    <Grid key={idx.toString()} item sm={12} md={6} lg={4}>
                      <VoucherCard
                        onClickDetail={() => setVoucherId(li.idVoucher)}
                        viewOnly={li.status_voucher}
                        {...li}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          </Stack>
        </Stack>
      </MainContainer>
      <Modal open={!!voucherId} onClose={() => setVoucherId(null)}>
        {voucherId ? (
          <DetailVoucherModal
            id={voucherId}
            onClose={() => setVoucherId(null)}
          />
        ) : (
          <span></span>
        )}
      </Modal>
    </>
  );
};

const DetailVoucherModal = ({
  id,
  onClose,
}: {
  id: number;
  onClose: () => void;
}) => {
  const modalStyles = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  const detailQuery = useDetailVoucher({ id });
  const detail = detailQuery.data;

  return (
    <Box sx={modalStyles}>
      {detailQuery.isLoading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight={200}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stack
            paddingX={2}
            paddingY={1}
            // direction="row"
            // alignItems="center"
            spacing={4}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6" fontWeight="700">
                {detail?.title}
              </Typography>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Stack>
            <Typography variant="body1" color="#6D6D6D">
              {detail?.deskripsi}
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <TimerOutline htmlColor="#9E9E9E" />
                <Typography variant="body2" color="#9E9E9E">
                  Berakhir{" "}
                  <Box
                    component="span"
                    sx={{
                      color: "#6D6D6D",
                      fontWeight: "bold",
                    }}
                  >
                    {detail?.kadaluwarsa}
                  </Box>
                </Typography>
              </Stack>
            </Stack>
            <Stack>
              <Typography>Syarat dan Ketentuan</Typography>
              <Stack component="ol">
                {detail?.syarat.map((syarat) => (
                  <Box
                    component="li"
                    key={syarat.id.toString()}
                    sx={{
                      color: "#6D6D6D",
                    }}
                  >
                    <Typography variant="body2" lineHeight="26px">
                      {syarat.val}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default PointRoyalty;
